<a mat-list-item
   [ngStyle]="{'padding-left': (depth * 1) + 'px'}" (click)="onItemSelected(item)"
   [ngClass]="{'active': this.checkRouteGroup(item), 'expanded': expanded}"
   class="menu-list-item"
   [routerLink]="item.route ? item.route : this.router.url"
   (keydown)="handleKeyPress($event)" (keyup)="handleKeyUp($event)">
   <span *ngIf="item?.kendoIcon" class="kendo-icon k-icon {{item.iconName}}"></span>
   <mat-icon *ngIf="!item?.kendoIcon" [class]="item.custom ? classItem + 'material-icons-extra' : classItem + 'material-icons'">{{item.iconName}}</mat-icon>
  {{item.displayName}}
  <span fxFlex *ngIf="item.children && item.children.length" class="arrow-item">
    <span fxFlex></span>
    <mat-icon [@indicatorRotate]="expanded ? 'expanded': 'collapsed'">
        arrow_drop_down
    </mat-icon>
  </span>
</a>
<div *ngIf="expanded">
  <app-menu-list-item *ngFor="let child of item.children" [item]="child" [depth]="depth+1">
  </app-menu-list-item>
</div>
