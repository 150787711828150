import { Project } from 'src/app/core/models/project';
import { ORDER_ITEM_SOURCE_TYPE_CODES, ORDER_STATUS, ORDER_TYPES } from './../../app.constant';
import { Job } from './../../core/models/job';
import { OrderItem } from './../../core/models/orderItem';
import { Serial } from './../../core/models/serial';
export class AbstractSerialPipe {
  protected getSerialNoWidthLenght(holderObject: OrderItem | Job): Serial {
    const serial: Serial = {};
    let orderItem: OrderItem;
    let job: Job;
    const isOrderItem = this.isOrderItem(holderObject);
    const isJob = !isOrderItem;
    if (isOrderItem) {
      orderItem = holderObject as OrderItem;
      job = orderItem.job;
    } else {
      job = holderObject as Job;
      orderItem = job.orderItem;
    }
    const hasLinkJobAndOrder =
      orderItem !== undefined && orderItem !== null && job !== undefined && job !== null ? true : false;

    // 'Stock' order type ===================================================
    if (orderItem && orderItem.order && orderItem.order.type === 'Stock') {
      if (this.isSourceTypeJob(orderItem)) {
        if (hasLinkJobAndOrder || isJob) {
          // linked or (unlinked and isJob) => Get data from 'job.serial'
          return job ? job.serial : null;
        }
        // It's unlinked and current holder object is OrderItem => Data should be empty
        return null;
      }
      if (this.isSourceTypeProject(orderItem)) {
        serial.width  = orderItem.width;
        serial.length = orderItem.length;
        return serial;
      }
      // Not 'Job' Source Type => Get data from 'orderItem.serial'
      return orderItem ? orderItem.serial : null;
    }

    // Not 'Stock' order type ===================================================
    // https://app.asana.com/0/1150501739018209/1190034328906250
    if (isOrderItem) {
      if (orderItem && orderItem.serial) {
        serial.serNum = this.getOrderSerialNum(orderItem);
      } else if (this.isSourceTypeJob(orderItem) && ![ORDER_STATUS.FILLED, ORDER_STATUS.COMPLETE, ORDER_STATUS.HISTORY].includes(orderItem.status)) {
        serial.serNum = this.getJobSerialNum(job);
      }
    } else if(this.isSourceTypeJob(orderItem)) {
      serial.serNum = this.getJobSerialNum(job)
    } else {
      serial.serNum = this.getOrderSerialNum(orderItem);
    }

    if (orderItem) {
      serial.width = orderItem.width;
      serial.length = orderItem.length;
    }
    return serial;
  }

  protected getProjectSerialNoWidthLenght(project: Project): Serial {
    let serial: Serial = {};
    if (!project) {
      return serial;
    }
    const orderItem = project.orderItem;

    // 'Stock' order type
    if (orderItem && orderItem.order && orderItem.order.type === ORDER_TYPES.STOCK) {
      if (this.isSourceTypeProject(orderItem)) {
        return project ? project.serial : null;
      }
      // Not 'Project' Source Type => Get data from 'orderItem.serial'
      return orderItem ? orderItem.serial : null;
    }

    if (orderItem) {
      serial.width  = orderItem.width;
      serial.length = orderItem.length;
    }

    return serial;
  }

  protected isJob(holderObject: OrderItem | Job): boolean {
    return !this.isOrderItem(holderObject);
  }

  protected isOrderItem(holderObject: OrderItem | Job): boolean {
    const orderItem: OrderItem = holderObject as OrderItem;
    return (
      orderItem &&
      orderItem.order &&
      orderItem.line !== null &&
      orderItem.line !== undefined &&
      orderItem.release !== null &&
      orderItem.release !== undefined
    );
  }

  protected isSourceTypeJob(orderItem: OrderItem): boolean {
    return orderItem && orderItem.sourceType === ORDER_ITEM_SOURCE_TYPE_CODES.JOB;
  }

  private isSourceTypeProject(orderItem: OrderItem): boolean {
    return orderItem && orderItem.sourceType === ORDER_ITEM_SOURCE_TYPE_CODES.PROJECT;
  }

  private getJobSerialNum(job: Job): string {
    return job && job.serial ? job.serial.serNum : null;
  }

  private getOrderSerialNum(orderItem: OrderItem): string {
    return orderItem && orderItem.serial ? orderItem.serial.serNum : null;
  }
}
