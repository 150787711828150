import { Directive, OnInit, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { OperationService } from 'src/app/core/services/operation.service';

@Directive({
  selector: '[appHideIfUnauthorized]'
})
export class HideIfUnauthorizedDirective implements OnInit, OnDestroy {
  @Input('appHideIfUnauthorized') operations: Array<string>; // Required permission passed in

  private hasView = false;
  private subscription: Subscription;

  constructor(
    private templateRef: TemplateRef<any>,
    private container: ViewContainerRef,
    private operationService: OperationService
  ) { }

  ngOnInit() {
    this.subscription = this.operationService.operations.subscribe(userOperations => {
      let hasPermission = this.operations.filter(o => userOperations.indexOf(o) >= 0).length > 0;
      if (hasPermission && !this.hasView) {
        this.hasView = true;
        this.container.createEmbeddedView(this.templateRef);
      } else if (!hasPermission && this.hasView) {
        this.container.clear();
        this.hasView = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
