import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, NavigationEnd, NavigationStart, PRIMARY_OUTLET, Router, UrlSegment } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AccountService } from './account.service';
import { Site } from '../models/site';
import { distinctUntilChanged, filter, map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SiteService {

  private sitesSubject: BehaviorSubject<Site[]> = new BehaviorSubject<Site[]>([]);
  private currentSiteSubject: BehaviorSubject<string | null> = new BehaviorSubject(null);

  readonly sites = this.sitesSubject.pipe(
    distinctUntilChanged(),
    shareReplay(1)
  );

  readonly currentSite = this.currentSiteSubject.pipe(
    distinctUntilChanged(),
    shareReplay(1)
  );

  constructor(
    private auth: AuthService,
    private account: AccountService,
    private router: Router,
    private http: HttpClient
  ) {
    this.auth.isAuthenticated$.subscribe(isAuthenticated => {
      if (isAuthenticated) {
        this.account.sites().subscribe(sites => this.setSites(sites));
      } else {
        this.setSites([]);
      }
    });
    this.router.events.pipe(
      filter(event => event instanceof NavigationStart),
      map((event: NavigationStart) => this.router.parseUrl(event.url)),
      map(tree => tree.root.children[PRIMARY_OUTLET]),
      filter(group => group && group.segments && group.segments.length > 0),
      map(group => group.segments[0].path),
      filter(site => environment.sites.includes(site))
    ).subscribe(site => this.setCurrentSite(site));
  }

  getAllSites() : Observable<Site[]> {
    return this.http.get<Site[]>(`${environment.apiUrl}sites`);
  }

  private setSites(sites: Site[]) {
    console.log(`${SiteService.name}#${SiteService.prototype.setSites.name}: ${JSON.stringify(sites)}`);
    this.sitesSubject.next(sites);
  }

  private setCurrentSite(site: string | null) {
    console.log(`${SiteService.name}#${SiteService.prototype.setCurrentSite.name}: ${site}`);
    this.currentSiteSubject.next(site)
  }

  public isHideFncBySite(site: string) {
    return ['MFGUHB'].includes(site);
  }
}
