import { Pipe, PipeTransform } from '@angular/core';
import { Job } from './../../core/models/job';
import { OrderItem } from './../../core/models/orderItem';
import { Serial } from './../../core/models/serial';
import { AbstractSizePipe } from './AbstractSizePipe';

@Pipe({
  name: 'size'
})
export class SizePipe extends AbstractSizePipe implements PipeTransform {
  SEPARATOR = 'x';

  transform(holderObject: OrderItem | Job, args?: any): string {
    if (!holderObject) {
      return null;
    }

    const orderItem = holderObject as OrderItem;
    const job = holderObject as Job;
    const serial: Serial = this.getSerialNoWidthLenght(holderObject);
    const width = orderItem.width ? orderItem.width
      : job.orderItem && job.orderItem.width? job.orderItem.width : null;
    const length = orderItem.length ? orderItem.length
      : job.orderItem && job.orderItem.length? job.orderItem.length : null;
    if (width || length) {
      const result = [width, length].join(this.SEPARATOR);
      return result !== this.SEPARATOR ? result : null;
    }
    return null;
  }
}
