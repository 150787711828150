import { Component, Input, OnInit, Directive } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { OOMSFilterDescriptor, FilterDescriptorEx, CompositeFilterDescriptorEx } from './ooms-filter-descriptor';

@Directive()
export abstract class FilterBaseComponent<TDescriptor extends OOMSFilterDescriptor> extends BaseComponent implements OnInit {
  @Input() public descriptor: TDescriptor;

  defaultFilter: FilterDescriptorEx | CompositeFilterDescriptorEx;

  constructor() {
    super();
  }

  abstract get value(): FilterDescriptorEx | CompositeFilterDescriptorEx;
  abstract get valid(): boolean;
  abstract init(): void;

  ngOnInit(): void {
    this.init();
  }
}
