<form [formGroup]="formGroup" class="k-form container">
  <div class="k-form-field">
    <kendo-dropdownlist formControlName="operator"
                        [data]="operators"
                        [itemDisabled]="itemDisabled"
                        [textField]="'text'"
                        [valueField]="'value'"
                        [valuePrimitive]="true"
                        (valueChange)="changeOperator($event)"
                        [popupSettings]="{ popupClass: 'group-items' }">
    </kendo-dropdownlist>
  </div>
  <div class="k-form-field">
    <kendo-datepicker #valuePicker1 formControlName="value1"
                      [format]="'MM/dd/yyyy'"
                      formatPlaceholder="formatPattern">
    </kendo-datepicker>
  </div>
  <div class="k-form-field" *ngIf="formGroup.get('operator').value === 'bt'">
    <kendo-datepicker formControlName="value2"
                      [ngClass]="{'ng-invalid': formGroup.errors?.toValueInvalid}"
                      [format]="'MM/dd/yyyy'"
                      formatPlaceholder="formatPattern">
    </kendo-datepicker>
  </div>
</form>
