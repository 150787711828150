import { Pipe, PipeTransform } from '@angular/core';
import { OrderItem } from '../../core/models/orderItem';
import { ORDER_TYPES } from '../../app.constant';

@Pipe({
  name: 'shipToCustomer'
})
export class ShipToCustomerPipe implements PipeTransform {

  transform(item: OrderItem, args?: any): any {
    if (item) {
      if (item.order.type === ORDER_TYPES.STOCK) {
        // Check warehouse
        if ((item.order && item.order.warehouse) || item.warehouse) {
          let warehouse;
          if (item.order && item.order.warehouse) {
            warehouse = item.order.warehouse;
          }

          if (!warehouse && item.warehouse) {
            warehouse = item.warehouse;
          }
          if (!warehouse.name) {
            return '';
          }
          return warehouse.name.trim();
        }
      } else {
        // Check customer
        if (item.customer || (item.order && item.order.customer)) {
          let cus = item.customer;
          if (!cus && item.order && item.order.customer) {
            cus = item.order.customer;
          }
          if (!cus.name) {
            return '';
          }
          return cus.name.trim();
        }
      }
    }
  }
}
