import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { environment } from '../environments/environment';

const routes: Routes = [
  {
    path: ':site/get-cp-link',
    loadChildren: () => import('./get-cp-link/get-cp-link.module').then(m => m.GetCPLinkModule)
  },
  {
    path: ':site/users',
    loadChildren: () => import('./users/users.module').then(m => m.UsersModule)
  },
  {
    path: ':site/user-groups',
    loadChildren: () => import('./user-groups/user-groups.module').then(m => m.UserGroupsModule)
  },
  {
    path: ':site/user-groups-cp',
    loadChildren: () => import('./customer-portal/user-groups-cp/user-groups-cp.module').then(m => m.UserGroupsCPModule)
  },
  {
    path: ':site/users-cp',
    loadChildren: () => import('./customer-portal/user-cp/users-cp.module').then(m => m.UsersCPModule)
  },
  {
    path: ':site/piece-work-pay',
    loadChildren: () => import('./piece-work-pay/piece-work-pay.module').then(m => m.PieceWorkPayModule)
  },
  {
    path: ':site/resources/equipment',
    loadChildren: () => import('./equipments/equipments.module').then(m => m.EquipmentsModule)
  },
  {
    path: ':site/building-designs',
    loadChildren: () => import('./building-designs/building-designs.module').then(m => m.BuildingDesignsModule)
  },
  {
    path: ':site/stock-requests',
    loadChildren: () => import('./stock-requests/stock-requests.module').then(m => m.StockRequestsModule)
  },
  {
    path: ':site/service-periods',
    loadChildren: () => import('./out-of-services/out-of-services.module').then(m => m.OutOfServicesModule)
  },
  {
    path: ':site/permit-plan-status',
    loadChildren: () => import('./permit-plan-status/permit-plan-status.module').then(m => m.PermitPlanStatusModule)
  },
  {
    path: ':site/production-planner',
    loadChildren: () => import('./production-planner/production-planner.module').then(m => m.ProductionPlannerModule)
  },
  {
    path: ':site/project-planner',
    loadChildren: () => import('./project-planner/project-planner.module').then(m => m.ProjectPlannerModule)
  },
  {
    path: ':site/delivery-planner',
    loadChildren: () => import('./delivery-planner/delivery-planner.module').then(m => m.DeliveryPlannerModule)
  },
  {
    path: ':site/permit-planner',
    loadChildren: () => import('./permit-planner/permit-planner.module').then(m => m.PermitPlannerModule)
  },
  {
    path: ':site/orders',
    loadChildren: () => import('./orders/orders.module').then(m => m.OrdersModule)
  },
  {
    path: ':site/attachments',
    loadChildren: () => import('./attachments/attachments.module').then(m => m.AttachmentsModule)
  },
  {
    path: ':site/products',
    loadChildren: () => import('./products/products.module').then(m => m.ProductsModule)
  },
  {
    path: ':site/payments',
    loadChildren: () => import('./payments/payments.module').then(m => m.PaymentsModule)
  },
  {
    path: ':site/stores',
    loadChildren: () => import('./stores/stores.module').then(m => m.StoresModule)
  },
  {
    path: ':site/merchants',
    loadChildren: () => import('./merchants/merchants.module').then(m => m.MerchantsModule)
  },
  {
    path: ':site/templates',
    loadChildren: () => import('./document-templates/document-templates.module').then(m => m.DocumentTemplatesModule)
  },
  {
    path: ':site/quotes',
    loadChildren: () => import('./quotes/quotes.module').then(m => m.QuotesModule)
  },
  {
    path: 'quotes',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: ':site/prebuilts',
    loadChildren: () => import('./prebuilts/prebuilts.module').then(m => m.PrebuiltsModule)
  },
  {
    path: ':site/preowned',
    loadChildren: () => import('./preowned/preowned.module').then(m => m.PreownedModule)
  },
  {
    path: ':site',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: '**',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always',
      preloadingStrategy: PreloadAllModules,
      enableTracing: environment.production === false
    })
  ],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {}
