import { Pipe, PipeTransform } from '@angular/core';
import { Lead } from 'src/app/core/models/lead';
import { AbstractSizePipe } from './AbstractSizePipe';

@Pipe({
  name: 'quoteLeadName'
})
export class QuoteLeadNamePipe extends AbstractSizePipe implements PipeTransform {
  transform(lead: Lead, args?: any): string {
    return `${lead.name}`;
  }
}
