import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";

@Component({
  selector: 'app-iframe-wrapper',
  templateUrl: './iframe-wrapper.component.html',
  styleUrls: ['./iframe-wrapper.component.scss']
})
export class IframeWrapperComponent implements OnInit {
  private _url: SafeUrl;
  @Input() set url(value: string | SafeUrl) {
    if(typeof value === 'string') {
      this._url = this.domSanitizer.bypassSecurityTrustResourceUrl(value);
    } else {
      this._url = value;
    }
  };

  get url(): SafeUrl {
    return this._url;
  }

  constructor(private domSanitizer: DomSanitizer) { }

  ngOnInit(): void {
  }

}
