import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {animate, state, style, transition, trigger} from '@angular/animations';

import {NavItem} from '../navbar/nav-item';
import {NavService} from '../navbar/nav.service';

@Component({
  selector: 'app-menu-list-item',
  templateUrl: './menu-list-item.component.html',
  styleUrls: ['./menu-list-item.component.scss'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({transform: 'rotate(-90deg)'})),
      state('expanded', style({transform: 'rotate(0deg)'})),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ]
})
export class MenuListItemComponent implements OnInit {
  expanded: boolean;
  @HostBinding('attr.aria-expanded') ariaExpanded;
  @Input() item: NavItem;
  @Input() depth: number;
  isPressCrtl = false;

  public classItem = 'routeIcon mat-icon notranslate ng-tns-c6-4 mat-icon-no-color ';

  constructor(public navService: NavService,
              public router: Router) {
    if (this.depth === undefined) {
      this.depth = 0;
    }
  }

  ngOnInit() {
    this.navService.currentUrl.subscribe((url: string) => {
      if (url && url.charAt(0) === '/') {
        url = url.substring(1);
      }
      let childItem;
      if (this.item.children) {
        childItem = this.item.children.find((item) => {
          const rs = this.checkRouteGroup(item);
          return rs ? item : false;
        });
      }
      if (childItem) {
        this.expanded = true;
      }

      this.ariaExpanded = this.expanded;
    });
  }

  onItemSelected(item: NavItem) {
    if (!this.isPressCrtl) {
      if (!item.children || !item.children.length) {
        this.router.navigate([item.route]);
      }
      if (item.children && item.children.length) {
        this.expanded = !this.expanded;
      }
    }
  }

  handleKeyPress(e) {
    if(navigator.platform.match('Mac')) {
      if (e.metaKey) {
        this.isPressCrtl = true;
      }
    } else {
      if (e.ctrlKey) {
        this.isPressCrtl = true;
      }
    }
    e.preventDefault();
  }

  handleKeyUp(e) {
    if(navigator.platform.match('Mac')) {
      if (e.metaKey) {
        this.isPressCrtl = false;
      }
    } else {
      if (e.ctrlKey) {
        this.isPressCrtl = false;
      }
    }
    e.preventDefault();
  }

  checkRouteGroup(item: NavItem) {
    const path = window.location.pathname;
    if (item.route === '/') {
      return item.route === path ? true : false;
    }
    if (path.includes('/edit')) {
      return item.route !== '/' && item.routeGroup ? item.routeGroup.find(r => path.includes(r))
      : ( item.route ? path.includes(item.route) : false );
    }
    return item.routeGroup ? item.routeGroup.find(r => this.router.isActive(r, true))
    : ( item.route ? this.router.isActive(item.route, true) : false );
  }
}
