import { PersonUser, SimplePersonUser } from '../models/person-user';
import { OriginalOrder } from '../models/return-order';
import { Warehouse } from '../models/warehouse';
import { Item } from '../models/item';
import { ReturnType } from '../models/return-type';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Customer } from '../models/customer';
import { ServiceOrder } from '../models/service-order';
import { Tax } from '../models/tax';
import { PunchItem } from '../models/punch-item';
import { environment } from 'src/environments/environment';

@Injectable()
export class ServiceOrderService {
  private topItems = 10000;

  constructor(private httpClient: HttpClient) { }

  public getOriginalOrders(site: string): Observable<OriginalOrder[]> {
    return this.httpClient
      .get(
        `${environment.apiUrl}${site}/orders?$filter=(type eq 'Regular' or type eq 'BarnMove')&$top=${this.topItems}`
      )
      .pipe(map((response: any) => response.data.map(item => ({ num: item.num, type: item.type }))));
  }

  public getOrderDetail(site: string, orderNum: string): Observable<ServiceOrder> {
    if (!orderNum) {
      return of(null);
    }
    return this.httpClient
      .get(`${environment.apiUrl}${site}/orders/${orderNum}`)
      .pipe(map((response: any) => response));
    // return this.httpClient
    //   .get(`https://demo6458973.mockable.io/orders-prefill`)
    //   .pipe(map((response: any) => response));
  }

  public getReturnTypes(): Observable<ReturnType[]> {
    return this.httpClient
      .get(`${environment.apiUrl}orders/returnTypes?$top=${this.topItems}`)
      .pipe(map((response: any) => response));
    // return this.httpClient.get(`https://demo6458973.mockable.io/returnTypes`).pipe(map((response: any) => response));
  }

  public getCustomerIds(site: string): Observable<Customer[]> {
    return this.httpClient
      .get(`${environment.apiUrl}${site}/customers?$filter=(seq eq 0)`)
      .pipe(map((response: any) => response));
    // return this.httpClient.get(`https://demo6458973.mockable.io/customerIds`).pipe(
    //   map((response: any) =>
    //     response.map((customer: Customer) => {
    //       customer.num = customer.num.trim();
    //       return customer;
    //     })
    //   )
    // );
  }

  public getShipTos(site: string, custNum: string): Observable<Customer[]> {
    return this.httpClient
      .get(`${environment.apiUrl}${site}/customers?$filter=num eq '${custNum}'`)
      .pipe(map((response: any) => response));
    // return this.httpClient.get(`https://demo6458973.mockable.io/shipTos`).pipe(map((response: any) => response));
  }

  public getCustomerInfo(site: string, custNum: string, custSeq: number): Observable<Customer> {
    return this.httpClient
      .get(`${environment.apiUrl}${site}/customers/${custNum}-${custSeq}?$top=${this.topItems}`)
      .pipe(map((response: any) => response));
  }

  public getEnteredBys(site: string): Observable<SimplePersonUser[]> {
    return this.httpClient
      .get(`${environment.apiUrl}${site}/resources?$filter=type eq 'Users'&$top=${this.topItems}`)
      .pipe(
        map((reponse: any) =>
          reponse.data.map((item: PersonUser) => {
            return {
              id: item.id,
              displayName: item.user.displayName
            };
          })
        )
      );
    // return this.httpClient
    //   .get(`https://demo6458973.mockable.io/enteredBys`)
    //   .pipe(map((reponse: any) => reponse.data.map((item: any) => item.user)));
  }

  public getItems(site: string): Observable<Item[]> {
    return this.httpClient
      .get(`${environment.apiUrl}${site}/items?$top=${this.topItems}`)
      .pipe(map((reponse: any) => reponse.map((res: any) => res)));
    // return this.httpClient
    //   .get(`https://demo6458973.mockable.io/items`)
    //   .pipe(map((reponse: any) => reponse.map((res: any) => res)));
  }

  public getWarehouses(site: string): Observable<Warehouse[]> {
    return this.httpClient
      .get(`${environment.apiUrl}${site}/whses?$top=${this.topItems}`)
      .pipe(map((reponse: any) => reponse.map((res: any) => res)));
    // return this.httpClient
    //   .get(`https://demo6458973.mockable.io/whses`)
    //   .pipe(map((reponse: any) => reponse.map((res: any) => res)));
  }

  public getTaxItems(site: string): Observable<Tax[]> {
    return this.httpClient
      .get(`${environment.apiUrl}${site}/taxes?$top=${this.topItems}`)
      .pipe(map((reponse: any) => reponse.map((res: any) => res)));
  }

  public getPunchItems(site: string, orderNum: string): Observable<PunchItem[]> {
    if (!orderNum) {
      return of(null);
    }

    return this.httpClient
      .get(`${environment.apiUrl}${site}/orders/${orderNum}/punchList?$top=${this.topItems}`)
      .pipe(map((reponse: any) => reponse.map((res: any) => res)));
  }

  public create(site: string, serviceOrder: ServiceOrder): Observable<ServiceOrder> {
    return this.httpClient.post<ServiceOrder>(`${environment.apiUrl}${site}/orders`, serviceOrder);
  }

  public update(site: string, orderNum: string, serviceOrder: ServiceOrder): Observable<ServiceOrder> {
    return this.httpClient.put<ServiceOrder>(`${environment.apiUrl}${site}/orders/${orderNum}`, serviceOrder);
  }

  public getCompanyName(site: string): string {
    return environment.companies.find(c => c.site == site)?.company;
  }
}
