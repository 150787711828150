<mat-sidenav-container>
  <mat-sidenav #appDrawer mode="side" opened>
    <app-navbar #appNavBar [appDrawer]="appDrawer"></app-navbar>
  </mat-sidenav>
  <mat-sidenav-content>
    <app-header [navBar]="appNavBar"></app-header>
    <div class="sidenav-content">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
<div *ngIf="!(isAuthenticated | async)" class="overlay"></div>
<ngx-spinner bdColor="rgb(255, 255, 255, 0)"
             color="#1497f3"
             size="medium"
             [fullScreen]="true"
             type="line-scale">
</ngx-spinner>
<div kendoDialogContainer></div>
