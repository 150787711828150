import { Injectable } from '@angular/core';
import { toODataString, DataResult, State } from '@progress/kendo-data-query';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { DateUtil } from '../utils/date.util';
import { BlackoutDay } from '../models/resourcePlan';
import { Serial } from '../models/serial';
import { Project } from '../models/project';
import { ITEM_PLAN_STATUSES, ORDER_ITEM_SOURCE_TYPE_CODES, PROJECT_STATUSES, PERMIT_SYSTEM_ID, PERMIT_TYPE, ORDER_TYPES } from 'src/app/app.constant';
import { environment } from 'src/environments/environment';
import { Poc } from '../models/poc';

export const ProjectPlanStatus = {
  Firm: {id: 'Firm', name: 'Firm'},
  Engineering: {id: 'Engineering', name: 'Engineering'},
  Permitting: {id: 'Permitting', name: 'Permitting'},
  Released: {id: 'Released', name: 'Released'},
  Concrete: {id: 'Concrete', name: 'Concrete'},
  DrawRequest: {id: 'DrawRequest', name: 'Draw Request 1'},
  Framing: {id: 'Framing', name: 'Framing'},
  DrawRequest2: {id: 'DrawRequest2', name: 'Draw Request 2'},
  Roofing: {id: 'Roofing', name: 'Roofing'},
  DrawRequest3: {id: 'DrawRequest3', name: 'Draw Request 3'},
  FinalInspection: {id: 'FinalInspection', name: 'Final Inspection'},
  FinalDrawWalkThru: {id: 'FinalDrawWalkThru', name: 'Final Draw/Walk-thru'},
  ProjectReview: {id: 'FinalProjectReview', name: 'Project Review'},
  Rework: {id: 'Rework', name: 'Rework'},
  Complete: {id: 'Complete', name: 'Complete'},
  Hold: {id: 'Hold', name: 'Hold'},
}

@Injectable({
  providedIn: 'root'
})
export class ProjectPlannerService {

  projectPlanStatusKey = 'projectPlanStatus';

  constructor(private http: HttpClient) { }

  toUTCDate(date: Date): Date {
    return date ? DateUtil.toUTCDate(date) : null;
  }

  stringToLocalDate(dateStr: string | null): Date {
    return dateStr ? DateUtil.stringToLocalDate(dateStr) : null;
  }

  private localToRemote(project: Project): Project {
    return {
      num: project.num,
      startDate:  this.toUTCDate(project.startDate),
      endDate:    this.toUTCDate(project.endDate),
      projectPlan: project.projectPlan ? {
        ...project.projectPlan,

        actualStartDate:  this.toUTCDate(project.projectPlan.actualStartDate),
        actualEndDate:    this.toUTCDate(project.projectPlan.actualEndDate)
      } : null,
      budget: project.budget
    };
  }

  private blackoutDayRemoteToLocal(bl: BlackoutDay): BlackoutDay {
    if (bl) {
      bl.startDate  = DateUtil.toLocalDate(bl.startDate);
      bl.endDate    = DateUtil.toLocalDate(bl.endDate);
    }
    return bl;
  }

  private remoteToLocal(item: any): Project {
    return {
      ...item,
      startDate:  this.stringToLocalDate(item.startDate),
      endDate:    this.stringToLocalDate(item.endDate),

      orderItem: item.orderItem ? {
        ...item.orderItem,

        dueDate:  this.stringToLocalDate(item.orderItem.dueDate),
        shipDate: this.stringToLocalDate(item.orderItem.shipDate),

        order: {
          ...item.orderItem.order,

          orderDate:  this.stringToLocalDate(item.orderItem.order.orderDate),
          shipDate:   this.stringToLocalDate(item.orderItem.order.shipDate),
          startDate:  this.stringToLocalDate(item.orderItem.order.startDate),
          dueDate:    this.stringToLocalDate(item.orderItem.order.dueDate)
        },

        itemPlan: item.orderItem.itemPlan ? {
          ...item.orderItem.itemPlan,
          startDate:  this.stringToLocalDate(item.orderItem.itemPlan.startDate),
          endDate:    this.stringToLocalDate(item.orderItem.itemPlan.endDate)
        } : null
      } : null,

      projectPlan: item.projectPlan ? {
        ...item.projectPlan,

        actualStartDate:  this.stringToLocalDate(item.projectPlan.actualStartDate),
        actualEndDate:    this.stringToLocalDate(item.projectPlan.actualEndDate)
      } : null
    } as Project;
  }

  public search(site: string, state: State): Observable<Project[]> {
    const queryStr = `${toODataString(state)}`;
    return this.http.get(`${environment.apiUrl}${site}/projects?${queryStr}`).pipe(map(
      (response: DataResult) => {
        return response.data.map((project: Project) => this.remoteToLocal(project));
      }
    ));
  }

  public searchNewProject(site: string, state: State): Observable<Project[]> {
    const newState: State = {
      ...state,
      filter: {
        ...state.filter,
        filters: [
          ...state.filter.filters,
          { field: 'projectStatus',           operator: 'eq',  value: PROJECT_STATUSES.ACTIVE },
          // { field: 'orderSourceType', operator: 'eq', value: ORDER_ITEM_SOURCE_TYPE_CODES.PROJECT },
          { field: 'orderStatus',             operator: 'neq',  value: 'Stopped'},
          { field: this.projectPlanStatusKey, operator: 'neq', value: ProjectPlanStatus.Firm.id },
          { field: this.projectPlanStatusKey, operator: 'neq', value: ProjectPlanStatus.Engineering.id },
          { field: this.projectPlanStatusKey, operator: 'neq', value: ProjectPlanStatus.Permitting.id },
          { field: this.projectPlanStatusKey, operator: 'neq', value: ProjectPlanStatus.Released.id },
          { field: this.projectPlanStatusKey, operator: 'neq', value: ProjectPlanStatus.Framing.id },
          { field: this.projectPlanStatusKey, operator: 'neq', value: ProjectPlanStatus.DrawRequest.id },
          { field: this.projectPlanStatusKey, operator: 'neq', value: ProjectPlanStatus.Roofing.id },
          { field: this.projectPlanStatusKey, operator: 'neq', value: ProjectPlanStatus.DrawRequest2.id },
          { field: this.projectPlanStatusKey, operator: 'neq', value: ProjectPlanStatus.Complete.id },
          { field: this.projectPlanStatusKey, operator: 'neq', value: ProjectPlanStatus.DrawRequest3.id },
          { field: this.projectPlanStatusKey, operator: 'neq', value: ProjectPlanStatus.FinalInspection.id },
          { field: this.projectPlanStatusKey, operator: 'neq', value: ProjectPlanStatus.FinalDrawWalkThru.id },
          { field: this.projectPlanStatusKey, operator: 'neq', value: ProjectPlanStatus.ProjectReview.id },
          { field: this.projectPlanStatusKey, operator: 'neq', value: ProjectPlanStatus.Rework.id },
          { field: this.projectPlanStatusKey, operator: 'neq', value: ProjectPlanStatus.Concrete.id },
          { field: this.projectPlanStatusKey, operator: 'neq', value: ProjectPlanStatus.Hold.id },
          {
            logic: 'or',
            filters: [
              { field: 'permitProcess', operator: 'eq', value: PERMIT_SYSTEM_ID.NONE },
              { field: 'permitType', operator: 'eq', value: PERMIT_TYPE.FINAL },
              { field: 'releasedToDeliveryPlanner', operator: 'eq', value: true },
            ]
          }
        ]
      },
      sort: [
        { field: 'itemPlanStartDate', dir: 'asc' }
      ]
    };
    return this.search(site, newState);
  }

  public searchOnHoldProject(site: string, state: State): Observable<Project[]> {
    const onHoldState: State = {
      ...state,
      filter: {
        ...state.filter,
        filters: [
          ...state.filter.filters,
          { field: this.projectPlanStatusKey, operator: 'eq', value: ProjectPlanStatus.Hold.id },
          { field: 'projectStatus', operator: 'neq', value: PROJECT_STATUSES.INACTIVE },
        ]
      },
      sort: [
        { field: 'itemPlanStartDate', dir: 'asc' }
      ]
    };
    return this.search(site, onHoldState);
  }

  public searchRecentlyCompleteProject(site: string, state: State): Observable<Project[]> {
    const recentlyCompleteState: State = {
      ...state,
      filter: {
        ...state.filter,
        filters: [
          ...state.filter.filters,
          { field: 'projectStatus', operator: 'neq', value: 'Complete' },
          { field: 'projectStatus', operator: 'neq', value: 'History' },
          { field: 'projectStatus', operator: 'neq', value: PROJECT_STATUSES.INACTIVE },
          { field: this.projectPlanStatusKey, operator: 'eq', value: ProjectPlanStatus.Complete.id }
        ]
      },
      sort: [
        { field: 'itemPlanStartDate', dir: 'asc' }
      ]
    };
    return this.search(site, recentlyCompleteState);
  }

  public searchProjectNoOrder(site: string, state: State = {
    filter: {
      logic: 'and',
      filters: []
    },
    take: 10000
  }): Observable<Project[]> {
    const recentlyCompleteState: State = {
      ...state,
      filter: {
        ...state.filter,
        filters: [
          ...state.filter.filters,
          { field: 'projectStatus', operator: 'eq',  value: PROJECT_STATUSES.ACTIVE },
          { field: 'orderType',     operator: 'ne',  value: ORDER_TYPES.REGULAR},
          { field: 'orderType',     operator: 'ne',  value: ORDER_TYPES.STOCK},
          { field: 'orderType',     operator: 'ne',  value: ORDER_TYPES.BARNMOVE},
        ]
      },
      sort: [
        { field: 'itemPlanStartDate', dir: 'asc' }
      ]
    };
    return this.search(site, recentlyCompleteState);
  }

  public searchPlanningProject(site: string, state: State): Observable<Project[]> {
    const planningJobState: State = {
      ...state,
      filter: {
        ...state.filter,
        filters: [
          ...state.filter.filters,
          {
            logic: 'or',
            filters: [
              { field: this.projectPlanStatusKey, operator: 'eq', value: ProjectPlanStatus.Firm.id },
              { field: this.projectPlanStatusKey, operator: 'eq', value: ProjectPlanStatus.Engineering.id },
              { field: this.projectPlanStatusKey, operator: 'eq', value: ProjectPlanStatus.Permitting.id },
              { field: this.projectPlanStatusKey, operator: 'eq', value: ProjectPlanStatus.Released.id },
              { field: this.projectPlanStatusKey, operator: 'eq', value: ProjectPlanStatus.Framing.id },
              { field: this.projectPlanStatusKey, operator: 'eq', value: ProjectPlanStatus.DrawRequest.id },
              { field: this.projectPlanStatusKey, operator: 'eq', value: ProjectPlanStatus.Roofing.id },
              { field: this.projectPlanStatusKey, operator: 'eq', value: ProjectPlanStatus.DrawRequest2.id },
              { field: this.projectPlanStatusKey, operator: 'eq', value: ProjectPlanStatus.Rework.id },
              { field: this.projectPlanStatusKey, operator: 'eq', value: ProjectPlanStatus.DrawRequest3.id },
              { field: this.projectPlanStatusKey, operator: 'eq', value: ProjectPlanStatus.FinalInspection.id },
              { field: this.projectPlanStatusKey, operator: 'eq', value: ProjectPlanStatus.FinalDrawWalkThru.id },
              { field: this.projectPlanStatusKey, operator: 'eq', value: ProjectPlanStatus.ProjectReview.id },
              { field: this.projectPlanStatusKey, operator: 'eq', value: ProjectPlanStatus.Concrete.id },
            ]
          },
          { field: 'projectStatus', operator: 'neq', value: PROJECT_STATUSES.INACTIVE },
        ]
      },
      sort: [
        { field: 'projectPlanPriority', dir: 'asc' }
      ]
    };
    return this.search(site, planningJobState);
  }

  public viewInERP(site: string, project: Project) {
    const url = `${environment.erpBaseUrl}/WSWebClient/Default.aspx?`
      + `config=${site}&form=Projects(FILTER(ProjNum='${project.num}')SETVARVALUES(InitialCommand=Refresh))`;
    window.open(url, '_blank');
  }

  public updateProjectPlan(site: string, project: Project): Observable<Project> {
    const postJob = this.localToRemote(project);
    return this.http.post(`${environment.apiUrl}${site}/projects/plans/${project.num}`, postJob).pipe(map(
      response => this.remoteToLocal(response)
    ));
  }

  public deleteProjectPlan(site: string, project: Project): Observable<Project> {
    const postJob = this.localToRemote(project);
    return this.http.delete(`${environment.apiUrl}${site}/projects/plans/${project.num}`).pipe(map(
      response => this.remoteToLocal(response)
    ));
  }

  public updateManyProjectPlan(site: string, projects: Project[]): Observable<any> {
    const postJobs = projects.map(project => this.localToRemote(project));
    return this.http.post(`${environment.apiUrl}${site}/projects/plans`, postJobs);
  }

  public getBlackoutDays(filter: {
    startDate: Date;
    endDate: Date;
  }): Observable<BlackoutDay[]> {
    const qstr = `${environment.apiUrl}blackoutDays?startDate=${filter.startDate.toISOString()}&endDate=${filter.endDate.toISOString()}`;
    return this.http.get(qstr).pipe(map(
      (response: any) =>  {
        return response.map(bl => this.blackoutDayRemoteToLocal(bl));
      }
    ));
  }

  public getSerials(site: string, project: Project): Observable<Serial[]> {
    const filter = `$filter=refNum eq '${project.num}' and refType eq 'J'`;
    return this.http.get(`${environment.apiUrl}${site}/serials?${filter}`)
      .pipe(map((reponse: any) => reponse));
  }

  public getPocs(site: string, projectNum: string, state: State): Observable<DataResult> {
    const queryStr = `${toODataString(state, { utcDates: true })}`; // Serialize the state
    return this.http.get(`${environment.apiUrl}${site}/projects/${projectNum}/pocs?${queryStr}`)
      .pipe(map((response: any) => ({
        data: response
          ? response.map((e: any, idx) => {return {
            ... e,
            date: DateUtil.stringToLocalDate(e.date),
          }})
          : null,
        total: response.length} as DataResult)));
  }
  
  public createPoc(site: string, projectNum: string, poc: Poc): Observable<any> {
    return this.http.post(`${environment.apiUrl}${site}/projects/${projectNum}/pocs`, poc);
  }

  public updatePoc(site: string, projectNum: string, pocId: string, poc: Poc): Observable<any> {
    return this.http.put(`${environment.apiUrl}${site}/projects/${projectNum}/pocs/${pocId}`, poc);
  }

  public deleteManyPoc(site: string, projectNum: string, pocIds: string[]): Observable<any> {
    return this.http.request('delete', `${environment.apiUrl}${site}/projects/${projectNum}/pocs`, {
      headers: {
        ['Content-Type']: 'application/json'
      },
      body: pocIds
    });
  }

  public updateBudget(site: string, projectNum: string, budget: number) {
    return this.http.put(`${environment.apiUrl}${site}/projects/${projectNum}/budget/${budget}`, {});
  }
}
