import { Pipe, PipeTransform } from '@angular/core';
import { DateUtil } from 'src/app/core/utils/date.util';

@Pipe({
  name: 'localDateBySite'
})
export class LocalDateBySitePipe implements PipeTransform {
  transform(date: Date, site?: string, combineTime = false, combineWeekday = false): string {
    if (!date || !site) {
      return null;
    }

    return DateUtil.getCommonLocalDateBySite(date, site, combineTime, combineWeekday);
  }
}
