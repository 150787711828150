export const SERVER_API_URL = 'https://localhost:5001/api/';
export const RESOURCE_PLAN_TYPES = {
    TRUCK: 'Truck',
    ONSITE_CREW: 'Onsite Crew',
    SERVICE: 'Service',
    PROJECT: 'Projects End Schedule',
    ADDITIONAL: {
        BLACKOUT_DAY: 'Blackout Day',
        ADD_RESOURCE: 'Add Resource',
    }
};

export const RESOURCE_PLAN_ZONES = {
    Truck:          'tru',
    'Onsite Crew':  'ons',
    Service:        'ser',
    'Projects End Schedule': 'pro'
};

export const USER_ROLE_TYPES = {
    ONSITE_CREW:            'Onsite Crew',
    SERVICE_TECH:           'Service Tech',
    PILOT_CAR_DRIVER:       'Pilot Car Driver',
    DELIVERY_DRIVER:        'Delivery Driver',
};

export const ORDER_TYPES = {
    REGULAR:    'Regular',
    RETURN:     'Return',
    STOCK:      'Stock',
    SERVICE:    'Service',
    CUSTOM:    'Preowned',
    BARNMOVE:   'BarnMove',
    CHANGE_REQUEST:   'ChangeRequest'
};

export const ORDER_STATUS = {
    STOPPED:    'Stopped',
    FILLED:    'Filled',
    COMPLETE:    'Complete',
    HISTORY:    'History',
    APPROVED:    'Approved',
    PENDING:    'Pending',
    CANCELLED:    'Cancelled',
};

export const JOB_STATUS = {
  STOPPED:    'Stopped',
  COMPLETE: 'Complete',
  FIRM: 'Firm',
  RELEASED: 'Released',
  HISTORY: 'History'
};

export const ORDER_SOURCE_TYPES = {
    JOB: 'Job',
    INVENTORY: 'Inventory',
    PROJECT: 'Project',
};

export const ORDER_ITEM_SOURCE_TYPE_CODES = {
  JOB: 'J',
  INVENTORY: 'I',
  PROJECT: 'K',
};

export const ORDER_ITEM_SOURCE_TYPE_DESC = {
    J: 'Job',
    I: 'Inventory',
    K: 'Project',
    P: 'Purchase Order'
};
export const ITEMPLAN_SHIPCODE = {
  OB: 'OB',
  TD: 'TD',
  SD: 'SD',
};

export const ORDER_PERMIT_STATUS = {
  PENDING: 'Pending',
  HOLD: 'Hold',
  APPROVED: 'Approved'
};

export const CHANGE_ORDER_STATUS = {
  NONE: 'None',
  PENDING: 'Pending',
  APPROVED: 'Approved'
};

export const ITEM_STATUS = {
    ORDERED:    'Ordered',
    PLANNED:    'Planned',
    FILLED:    'Filled',
    COMPLETE:    'Complete',
    HISTORY:    'History'
};

export const EQUIPMENT_TYPES = {
    TRAILER:                'Trailer',
    ONSITE_CREW:            'Onsite Crew',
    SERVICE_TRUCK:          'Service Truck',
    PILOT_CAR:              'Pilot Car',
    DELIVERY_TRUCK:         'Delivery Truck',
    PROJECT_END:            'Projects End Schedule',
};

export const ITEM_PLAN_ACTIONS = {
    MOVE_UP: 1,
    MOVE_DOWN: 2,
    UNSCHEDULE: 3,
    MARK_AS_CONFIRMED: 4,
    MARK_AS_SHIPPED: 5,
    MARK_AS_COMPLETED: 6,
    MARK_AS_HOLD: 7,
};

export const ITEM_PLAN_STATUSES = {
    UNSCHEDULED:        'Unscheduled',
    PROPOSED_SCHEDULE:  'ProposedSchedule',
    SCHEDULED:          'Scheduled',
    DONE:               'Done',
    COMPLETE:           'Complete',
    HOLD:               'Hold',
    STOPPED:            'Stopped',
};

export const PROJECT_STATUSES = {
  ACTIVE:         'Active',
  INACTIVE:       'Inactive',
  COMPLETE:       'Complete',
  HISTORY:        'History',
};

export const ITEM_STATUSES_ERP = {
    COMPLETE:           'Complete',
};

export const ITEM_PLAN_STATUSES_LABELS = {
    Unscheduled:        'Unscheduled',
    ProposedSchedule:   'Proposed Schedule',
    Scheduled:          'Scheduled',
    Done:               'Done',
    Complete:           'Complete',
    Hold:               'Hold',
    Stopped:            'Stopped',
};

export const BLACKOUT_DAY_TYPES = {
    WEEKEND: 'Weekend',
    AD_HOC: 'Ad-Hoc',
    PUBLIC_HOLIDAY: 'Public Holiday',
};

export const FILTER_EXPRESSIONS = {
    '=':    'eq',
    '|':    'or',
    '&':    'and',
    '<':    'lt',
    '>':    'gt',
    '>=':   'ge',
    '<=':   'le',
    '<>':   'ne',
    '!=':   'ne',
    '*':    'endswith',
    ALL:    'all',
    ANY:    'any',
    AND:    'and',
    OR:     'or',
    EQ:     'eq',
    LT:     'lt',
    GT:     'gt',
    GE:     'ge',
    LE:     'le',
    NE:     'ne',
    NEQ:     'neq',
    TRIM:	            `trim`,
    FLOOR:	            `floor`,
    ROUND:	            `round`,
    CONCAT:	            `concat`,
    REPLACE:            `replace`,
    INDEXOF:            `indexof`,
    TOLOWER:	        `tolower`,
    TOUPPER:	        `toupper`,
    CEILING:	        `ceiling`,
    ENDSWITH:           `endswith`,
    SUBSTRING:          `substring`,
    STARTSWITH:         `startswith`,
    SUBSTRINGOF:        `substringof`,
    TRIMEXPR:	        `trim({0})`,
    ROUNDEXPR:	        `round({0})`,
    FLOOREXPR:	        `floor({0})`,
    CONCATEXPR:	        `concat(concat({0}, '{1}'), {2})`,
    INDEXOFEXPR:        `indexof({0}, '{1}')`,
    REPLACEEXPR:        `replace({0}, '{1}', '{2}')`,
    TOLOWEREXPR:	    `tolower({0})`,
    TOUPPEREXPR:	    `toupper({0})`,
    CEILINGEXPR:	    `ceiling({0})`,
    ENDSWITHEXPR:       `endswith({0}, '{1}')`,
    SUBSTRINGEXPR:      `substring({0}, {1})`,
    STARTSWITHEXPR:     `startswith({0}, '{1}')`,
    SUBSTRINGOFEXPR:    `substringof({0}, '{1}')`,
};

export const PHONE_MASK = '(999) 000-0000';

export const SESSION_STORAGE_STATE = {
  ORDER_LIST_STATE: 'order-list-state',
  QUOTE_LIST_STATE: 'quote-list-state',
  TEXT_LIST_SORT: 'text-list-sort',
  EMAIL_LIST_SORT: 'email-list-sort',
  CALL_LIST_SORT: 'call-list-sort',
  PERMIT_PLANNER_LIST_STATE: 'permit-planner-list-state',
  PRODUCTION_LIST_STATE: 'production-list-state',
  PROJECT_LIST_STATE: 'project-list-state',
  DELIVERY_LIST_STATE: 'delivery-list-state',
  SITE: 'site',
  PRODUCT_LIST_STATE: 'product-list-state',
  STORE_LIST_STATE: 'store-list-state',
  MERCHANT_LIST_STATE: 'merchant-list-state',
  NET_PRICE_VISIBILITY: 'net-price-visibility',
  TEMPLATE_LIST_STATE: 'template-list-state',
  PREBUILTS_LIST_STATE: 'prebuilts-list-state',
  PREBUILTS_STATUS_LIST: 'prebuilts-status-list',
  USERS: 'users',
  PAYROLL_LIST_STATE: 'payroll-list-state',
  PAYPERIODS_LIST_STATE: 'pay-retiods-list-state',
  REPORT_EARNINGS_STATE: 'report-earnings-list-state',
  EMPLOYEE_TIMECARDS_STATE: 'employee-timecards-list-state'
};

export const UPDATE_KEY_SIGNAL = {
  RS_TRAILER: 'Trailer',
  RS_DRIVER: 'Driver',
  RS_PILOT_CAR: 'PilotCars',

  ITEM_DRAG_END: 'itemDragEnd',
  ITEM_DAY_CHANGE: 'itemDayChange',
  ITEM_SHIP_DATE: 'itemShipDate',
  ITEM_ATTACHMENT: 'itemAttachment',
  ITEM_ROUTE_FLAG: 'RoutePermit',
  ITEM_ROUTE_LINK: 'RouteLink',
  ITEM_BUILDER_PAY: 'BuilderPay',
}

export enum Operations {
  OrderCreate = 'orders.create',
  OrderUpdate = 'orders.update',
  OrderDelete = 'orders.delete',
  OrderRead   = 'orders.read',
  OrderViewLog   = 'orders.viewlog',
  OrderViewAttachment   = 'orders.view_attachment',
  OrderAddAttachment   = 'orders.add_attachment',
  OrderDeleteAttachment   = 'orders.delete_attachment',
  OrderCreateErpOrderLine   = 'orders.create-erp-order-line',
  OrderSyncErpPayment = 'orders.sync-erp-payment',
  OrderGetCPLink = 'orders.generate-cp-link',
  OrderStoreUpdate = 'orders.store-update',
  OrderSalespersonUpdate = 'orders.salesperson-update',
  OrderOverwriteBom = 'orders.overwrite-bom',
  OrderOrphanNewJob = 'orders.orphan-new-job',
  OrderDeleteNewJob = 'orders.delete-new-job',
  OrderChangeRequestMake = 'orders.change-request-make',
  OrderChangeRequestEdit = 'orders.change-request-edit',
  OrderChangeRequestApproval = 'orders.change-request-approval',
  OrderGenerateJob = 'orders.generate-job',
  OrderChangeOrder = 'orders.update-change-order',
  OrderPmtStatus = 'orders.update-pmt-status',
  OrderManageOrders = 'orders.manage-orders',
  OrderCreateNewVersion = 'orders.create-new-version',

  QuoteCreate = 'quotes.create',
  QuoteUpdate = 'quotes.update',
  QuoteDelete = 'quotes.delete',
  QuoteRead   = 'quotes.read',
  QuoteStoreUpdate   = 'quotes.store-update',
  QuoteSalespersonUpdate = 'quotes.salesperson-update',
  QuoteViewLog   = 'quotes.viewlog',

  NotesCreate = 'notes.create',
  NotesUpdate = 'notes.update',
  NotesDelete = 'notes.delete',
  NotesRead   = 'notes.read',

  CallCreate = 'calls.create',
  CallRead   = 'calls.read',

  EmailCreate = 'emails.create',
  EmailRead   = 'emails.read',

  TaskCreate = 'tasks.create',
  TaskUpdate = 'tasks.update',
  TaskRead = 'tasks.read',
  TaskDelete = 'tasks.delete',

  TextCreate = 'texts.create',
  TextRead = 'texts.read',

  PermitStatusDelete = 'permitplanstatus.delete',
  PermitStatusRead = 'permitplanstatus.read',
  PermitStatusUpdate = 'permitplanstatus.update',
  PermitStatusCreate = 'permitplanstatus.create',

  PermitCreate = 'permitplanner.create',
  PermitRead = 'permitplanner.read',
  SettingReleasedDP = 'settings.release-dp',

  ProjectRead = 'projectplanner.read',
  ProjectCreate = 'projectplanner.create',
  ProjectUpdateBudget = 'projectplanner.update-budget',
  PocCreate = 'poc.create',
  PocUpdate = 'poc.update',
  PocDelete = 'poc.delete',

  UserRead = 'users.read',
  UserDelete = 'users.delete',
  UserUpdate = 'users.update',
  UserCreate = 'users.create',

  UserGroupRead = 'usergroups.read',
  UserGroupDelete = 'usergroups.delete',
  UserGroupUpdate = 'usergroups.update',
  UserGroupCreate = 'usergroups.create',

  OosPeriodCreate = 'oosperiods.create',
  OosPeriodUpdate = 'oosperiods.update',
  OosPeriodDelete = 'oosperiods.delete',
  OosPeriodRead = 'oosperiods.read',

  ProductionCreate = 'productionplanner.create',
  ProductionRead = 'productionplanner.read',
  ProductionReadNetPrice = 'productionplanner.readnetprice',

  EquipmentDefaultCreate = 'equipmentdefaults.create',
  EquipmentDefaultRead = 'equipmentdefaults.read',

  EquipmentCreate = 'equipments.create',
  EquipmentUpdate = 'equipments.update',
  EquipmentDelete = 'equipments.delete',
  EquipmentRead = 'equipments.read',

  PaymentCreate = 'payments.create',
  PaymentUpdate = 'payments.update',
  PaymentDelete = 'payments.delete',
  PaymentRead = 'payments.read',
  PaymentGenerateSchedule = 'payments.generate-default-schedule',
  PaymentNegativeAmount = 'payments.negative-amount',
  PaymentPostToERPFlag = 'payments.update-sync-flag',

  DeliveryCreate = 'deliveryplanner.create',
  DeliveryRead = 'deliveryplanner.read',
  DeliveryComplete = 'deliveryplanner.complete',

  TemplateRead = 'templates.read',
  TemplateDelete = 'templates.delete',
  TemplateUpdate = 'templates.update',
  TemplateCreate = 'templates.create',
  TemplateImportExport = 'templates.import-export',

  PrebuiltsRead = 'prebuilts.read',
  PrebuiltsDelete = 'prebuilts.delete',
  PrebuiltsUpdate = 'prebuilts.update',
  PrebuiltsUpdateMaster = 'prebuilts.update-master',
  PrebuiltsCreate = 'prebuilts.create',
  PrebuiltsCreateQuote = 'prebuilts.create-quote',
  PrebuiltsViewQuote = 'prebuilts.view-quote',
  PrebuiltsViewOrder = 'prebuilts.view-order',
  PrebuiltsDeleteMaster = 'prebuilts.delete-master',
  PrebuiltsEditSalesStatus = 'prebuilts.edit-salesstatus',
  PrebuiltsEditInternalNotes = 'prebuilts.edit-internalnotes',
  PrebuiltsEditPublicNotes = 'prebuilts.edit-publicnotes',
  PrebuiltsEditImages = 'prebuilts.edit-images',
  PrebuiltsBackgroundConfig = 'prebuilts.background-config',

  DocumentRead = 'documents.read',
  DocumentDelete = 'documents.delete',
  DocumentUpdate = 'documents.update',
  DocumentCreate = 'documents.create',
  DocumentViewLog = 'documents.viewlog',

  SendForSigning = 'e-signatures.send-for-signing',

  ReportEarningsExport = 'earnings.export',
  ReportEarningsRead = 'earnings.read',

  EmployeeTimecardsRead = 'timecards.read',
  EmployeeTimecardsImport = 'timecards.import',

  WorkbenchsCreate = 'workbenchs.create',
  WorkbenchsUpdate = 'workbenchs.update',
  WorkbenchsRead = 'workbenchs.read',
  WorkbenchsGenerate = 'workbenchs.generate',
  WorkbenchsSplitOperation = 'workbenchs.split-operation',
  WorkbenchsItemCreate = 'workbenchitems.create',
  WorkbenchsItemDelete = 'workbenchitems.delete',
  WorkbenchsExport = 'workbenchs.export',
  WorkbenchsParameter = 'workbenchs.param',
  WorkbenchsEditShipToAddress = 'workbenchs.editshiptoaddress',

  TransactionsRead = 'transactions.read',
  NppsCreate = 'transactions.create',
  NppsUpdate = 'transactions.update',
  NppsDelete = 'transactions.delete',
  TransactionsExport = 'transactions.export',

  PayrollsRead = 'payrolls.read',
  MeetingHoursCreate = 'meetinghours.create',
  MeetingHoursUpdate = 'meetinghours.update',
  PayrollsExport = 'payrolls.export',

  PayPeriodsRead = 'payperiods.read',
  PayPeriodsCreate = 'payperiods.create',
  PayPeriodsLock = 'payperiods.lock',

  EmployeesRead = 'employees.read',
  EmployeesImport = 'employees.import',
  EmployeesDelete = 'employees.delete',
  EmployeesNPRateUpdate = 'employees.nprate.update',
  EmployeesMinRateUpdate = 'employees.minrate.update',
  EmployeesStatusUpdate = 'employees.status.update',
  EmployeesSettingsUpdate = 'settings.update',
  EmployeesExport = 'employees.export',

  BPGroupsRead = 'bpgroups.read',
  BPGroupsCreate = 'bpgroups.create',
  BPGroupsUpdate = 'bpgroups.update',
  BPGroupsDelete = 'bpgroups.delete',
  
  ProductDefaultsRead = 'productdefaults.read',
  ProductDefaultsCreate = 'productdefaults.create',
  ProductDefaultsUpdate = 'productdefaults.update',
  ProductDefaultsDelete = 'productdefaults.delete',
  ProductDefaultsDeactivate = 'productdefaults.deactivate',
  ProductDefaultsBackgroundCfg = 'productdefaults.background-config',

  StockRequestsRead = 'stockrequests.read',
  StockRequestsUpdate = 'stockrequests.update',
  StockRequestsApprove = 'stockrequests.approve',
  StockRequestsPrebuiltsCreate = 'stockrequests.create-from-prebuilts',
  StockRequestsBuildingCreate = 'stockrequests.create-from-bp',
  StockRequestsDelete = 'stockrequests.delete',

  BuildingPackageRead = 'buildingpackages.read',
  BuildingPackageCreate = 'buildingpackages.create',
  BuildingPackageUpdate = 'buildingpackages.update',
  BuildingPackageDuplicate = 'buildingpackages.duplicate',
  BuildingPackageDelete = 'buildingpackages.delete',
  BuildingPackageDeactivate = 'buildingpackages.deactivate',
  BuildingPackageUpdateGroups = 'buildingpackages.update-group',
  BuildingPackageUpdatePackageName = 'buildingpackages.update-package-name',
  BuildingPackageUpdateVariant = 'buildingpackages.allow-variants',
  BuildingPackageUpdateStockReq = 'buildingpackages.allow-stock-request',
  BuildingPackageBackgroundCfg = 'buildingpackages.background-config',

  PreownedRead = 'preowned.read',
  PreownedCreate = 'preowned.create',
  PreownedUpdate = 'preowned.update',
  PreownedUpdateMaster = 'preowned.update-master',
  PreownedDelete = 'preowned.delete',
  PreownedCreateSerial = 'preowned.create-serial',
  PreownedImport = 'preowned.import-serial',
  PreownedCreateQuote = 'preowned.create-quote',
  PreownedViewQuote = 'preowned.view-quote',
  PreownedViewOrder = 'preowned.view-order',
  PreownedDeleteMaster = 'preowned.delete-master',
  PreownedEditWhse = 'preowned.edit-whse',
  PreownedStop = 'preowned.stop-order',
  PreownedEditSalesStatus = 'preowned.edit-salesstatus',
  PreownedEditInternalNotes = 'preowned.edit-internalnotes',
  PreownedEditPublicNotes = 'preowned.edit-publicnotes',
  PreownedEditImages = 'preowned.edit-images',
  PreownedReturnBuilding = 'preowned.return-building',
  PreownedBackgroundConfig = 'preowned.background-config',
  
  Employees1099Read = '1099employees.read',
  Employees1099StatusUpdate = '1099employees.status.update',
  Employees1099Delete = '1099employees.delete',
  Employees1099Create = '1099employees.create',
  Employees1099Export = '1099employees.export',
  Employees1099Parameter = '1099employees.param',

  Payroll1099Export = '1099payrolls.export',
  Payroll1099Read = '1099payrolls.read',

  InsuranceCertificatesRead = 'inscert.read',
  InsuranceCertificatesCreate = 'inscert.create',
  InsuranceCertificatesUpdate = 'inscert.update',
  InsuranceCertificatesDelete = 'inscert.delete',
  InsuranceCertificatesAttachmentRead = 'inscert.attachment.read',
  InsuranceCertificatesAttachmentCreate = 'inscert.attachment.create',
  InsuranceCertificatesAttachmentDelete = 'inscert.attachment.delete',
  InsuranceCertificatesParameter = 'inscert.param',
}

export const PO_STATUS = {
  FILLED:    'Filled',
  UNFILLED:    'Unfilled',
};

export const PERMIT_TYPE = {
  PROCESSING:    'Processing',
  FINAL:    'Final',
  SYSTEM:    'System',
};

export const PERMIT_SYSTEM_ID = {
  HOLD:    'H',
  NONE:    'NA',
  NOTSTARTED:    'NS',
};

export const LOG_ACTION = {
  CREATE:    'Create',
  UPDATE:    'Update',
  DELETE:    'Delete',
  POST_TO_ERP: 'Post pmt to ERP',
  RESET_POST_TO_ERP: 'Reset Post To ERP'
};

export const LOG_ITEM_FIELD = {
  PAYMENT:   'Payment'
}

export const LOG_MODULE = {
  PAYMENT:   'Payment',
  ORDERS:   'Orders',
}

export const LOG_MODULE_QUOTE = {
  DOCUMENTS:   'Documents',
  LEADADDRESSES:   'LeadAddresses',
  LEADS:   'Leads',
  PRODUCTS:   'Products',
  QUOTES:   'Quotes',
  SALESMAN:   'Salesman'
}

export const CHANGELOG_MODULE = {
  ATTACHMENTS:   'Attachments',
  DELIVERY_PLANNER:   'Delivery Planner',
  NOTES:   'Notes',
  ORDER_ITEMS:   'Order Items',
  ORDERS:   'Orders',
  PAYMENT:   'Payment',
  PAYMENT_SCHEDULES:   'Payment Schedules',
  PREMIT_PLANNER:   'Permit Planner',
  PILOT_CARS:   'Pilot Cars',
  PRODUCTION_PLANNER:   'Production Planner',
  PROJECT_PLANNER:   'Project Planner',
  RESOURCE_LOADS:   'Resource Loads',
  TASKS:   'Tasks'
}

export const MODULE_STATUSES = {
  ORDERS: { // Orders & Order Items Statuses
    P: 'Planned',
    O: 'Ordered',
    C: 'Complete',
    H: 'History',
    S: 'Stopped',
    F: 'Filled'
  },
  ITEM_PLAN_STATUS: {
    UNSCHEDULED: 'U',
    PROPOSED_SCHEDULE: 'PS',
    SCHEDULED: 'SC',
    SHIPPED: 'S',
    COMPLETE: 'C',
    HOLD: 'H',
    STOPPED: 'ST'
  },
  ITEM_PLAN: { // Delivery Statuses
    U: 'Unscheduled',
    PS: 'Proposed Schedule',
    SC: 'Scheduled',
    S: 'Shipped',
    C: 'Complete',
    H: 'Hold',
    ST: 'Stopped'
  },
  JOB_PLAN: { // Production Statuses
    F: 'Firm',
    R: 'Released',
    K: 'KitComplete',
    C: 'Complete',
    RW: 'Rework',
    S: 'Stopped',
    H: 'Hold'
  },
  PROJECT_PLAN: { // Project Statuses
    F: 'Firm',
    E: 'Engineering',
    P: 'Permitting',
    R: 'Released',
    CC: 'Concrete',
    DReq: 'DrawRequest',
    FM: 'Framing',
    DReq2: 'DrawRequest2',
    RF: 'Roofing',
    DReq3: 'DrawRequest3',
    FI: 'FinalInspection',
    FD: 'FinalDrawWalkThru',
    FPRev: 'FinalPaymentReview',
    C: 'Complete',
    RW: 'Rework',
    H: 'Hold'
  },
  PERMIT_PLAN: { // Permit Statuses
    H: 'Hold',
    NS: 'Not Started',
    'NA - None': 'None'
  },
  QUOTES_STATUS: {
    SOLD: 'S',
    QUOTING: 'Q'
  },
  QUOTES_PLAN_STATUS: {
    S: 'Sold',
    Q: 'Quoting'
  }
};

export const DOCUMENT_TEMPLATE_NAME = {
  COC:    'Certificate Of Completion'
};

export const HUBSPOT_TYPE = {
  DEAL: 'Deal',
  SERVICE: 'Ticket'
};

export const PAYMENT_STATUS = {
  APPROVED: 'Approved',
  DECLINED: 'Declined',
  ERROR: 'Error',
  HELD: 'Held',
};

export const PAY_PERIOD_INCREMENT = {
  WEEKLY: 'Bi-Weekly',
  MONTHLY: 'Monthly',
};

export const DOCUMENT_READY_STATUS = {
  READY: 1,
  NON_READY: 0,
  NON_DOCUMENT: -1,
};

export const PW_OPERATION = {
  NPP: 'NPP',
};

export const WORKBENCH_SECTION = {
  BASE: 'Base',
  OPTION: 'Options',
};

export const WORKBENCH_1099 = {
  OPERATION: '1099',
  DESCRIPTION_PREMIUM: '1099 Premium',
  DESCRIPTION_MILEAGE: 'Mileage',
};

export const PREVIEW_SCREEN = {
  EMPLOYEE_TIMECARD: 'Employee Timecard',
  EMPLOYEE: 'Employee',
  EMPLOYEE_1099: 'Employee 1099',
};

export const CONFIG_BUILDING_DESIGN = {
  DEFAULT_PRODUCT: 'Default Product',
  BUILDING_PACKAGE: 'Building Package',
  STOCK_REQUEST: 'Stock Request',
};

export const CONFIG_BUILDING_DESIGN_ACTION = {
  NEW: 'New',
  EDIT: 'Edit',
  DUPLICATE: 'Duplicate',
};

export const MASTER_TRANSITION = {
  PREBUILT: 'prebuilts',
  PREOWNED: 'preowned',
};

export const CONFIG_ACTION_TYPE = {
  NEW_CR: 'NewCR',
  EDIT_CR: 'EditCR',
  ORDER_EDIT: 'OrderEdit',
  QUOTE_EDIT: 'QuoteEdit',
  DUPLICATE_QUOTE: 'duplicateQuote',
};

export const BUILDING_DESIGN_EVENT_TYPE = {
  ProductTemplateNew: 'ProductTemplateNew',
  ProductTemplateEdit: 'ProductTemplateEdit',
  BuildingPackageNew: 'BuildingPackageNew',
  BuildingPackageEdit: 'BuildingPackageEdit',
  BuildingPackageDuplicate: 'BuildingPackageDuplicate',
  StockRequestNew: 'StockRequestNew',
  StockRequestEdit: 'StockRequestEdit',
};

export const STOCK_REQUEST_STATUS = {
  DECLINED: 'Declined',
  APPROVED: 'Approved',
  NEW: 'New'
}

export const YES_NO = {
  YES: 'Yes',
  NO: 'No'
}

export const RUN_BACKGROUND_ACTION_TYPE = {
  PrebuiltRunBg: 'PrebuiltRunBg',
  PreownedRunBg: 'PreownedRunBg',
  BuildingPackageRunBg: 'BuildingPackageRunBg',
  DefaultProductRunBg: 'DefaultProductRunBg'
}

export const LOG_TYPE = {
  ORDER: 'order',
  QUOTE: 'quote'
};

export const USER_ID = {
  SYSTEM: 'system',
};