import { State } from './../models/state';
import { Country } from './../models/country';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataResult } from '@progress/kendo-data-query';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Customer } from 'src/app/core/models/customer';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomersService {

  constructor(private http: HttpClient) { }

  public get(site: string, num: string, seq: number): Observable<Customer> {
    return this.http.get(`${environment.apiUrl}${site}/customers/${num}-${seq}`).pipe(map(
      (response: any) => response
    ));
  }

  public getCalls(site: string, num: string, seq: number): Observable<DataResult> {
    return this.http.get(`${environment.apiUrl}${site}/customers/${num}-${seq}/calls`).pipe(map(
      (res: any) => {
        if (res.response && res.response.data) {
          return ({
            data: res.response.data,
            total: res.response.data.length
          } as DataResult);
        } else {
          return ({
            data: [],
            total: 0
          } as DataResult);
        }
      }
    ));
  }

  public createCall(site: string, num: string, seq: number, email: any): Observable<DataResult> {
    return this.http.post<any>(`${environment.apiUrl}${site}/customers/${num}-${seq}/calls`, email);
  }

  public getTexts(site: string, num: string, seq: number): Observable<DataResult> {
    return this.http.get(`${environment.apiUrl}${site}/customers/${num}-${seq}/texts`).pipe(map(
      (res: any) => {
        if (res.response && res.response.data) {
          return ({
            data: res.response.data,
            total: res.response.data.length
          } as DataResult);
        } else {
          return ({
            data: [],
            total: 0
          } as DataResult);
        }
      }
    ));
  }

  public createText(site: string, num: string, seq: number, email: any): Observable<DataResult> {
    return this.http.post<any>(`${environment.apiUrl}${site}/customers/${num}-${seq}/texts`, email);
  }

  public getEmails(site: string, num: string, seq: number): Observable<DataResult> {
    return this.http.get(`${environment.apiUrl}${site}/customers/${num}-${seq}/emails`).pipe(map(
      (response: any) => {
        if (response) {
          return ({
            data: response,
            total: response.length
          } as DataResult);
        } else {
          return ({
            data: [],
            total: 0
          } as DataResult);
        }
      }
    ));
  }

  public createEmail(site: string, num: string, seq: number, email: any): Observable<DataResult> {
    return this.http.post<any>(`${environment.apiUrl}${site}/customers/${num}-${seq}/emails`, email);
  }

  public getCountries(site: string): Observable<Country[]> {
    return this.http.get(`${environment.apiUrl}${site}/countries`).pipe(map((response: any) => response));
  }

  public getStates(site: string, country: string): Observable<State[]> {
    const filter = country ? `?$filter=country eq '${country}'` : '';
    return this.http.get(`${environment.apiUrl}${site}/states${filter}`).pipe(map((response: any) => response));
  }

  public create(site: string, customer: Customer): Observable<Customer> {
    return this.http
      .post<any>(`${environment.apiUrl}${site}/customers`, customer)
      .pipe(map((response: any) => response));
  }

}
