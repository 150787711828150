import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-account-menu',
  templateUrl: './account-menu.component.html',
  styleUrls: ['./account-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AccountMenuComponent implements OnInit {

  imageUrl = "/assets/img/default-avatar.jpg";

  name = "";

  actions = [
    {
      text: "Logout",
      click: () => {
        this.auth.logout();
      }
    }
  ];

  constructor(private auth: AuthService) { }

  ngOnInit(): void {
    this.auth.user$.subscribe(u => {
      if (u) {
        this.imageUrl = u.picture ?? this.imageUrl;
        this.name = u.name;
      }
    });
  }
}
