import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'documentStatus'
})
export class DocumentStatusPipe implements PipeTransform {
  transform(documentReady: number): string {
    switch (documentReady) {
      case -1:
        return 'N/A';
      case 0:
        return 'Not Ready';
      case 1: 
        return 'Ready';
      default:
        return '';
    }
  }
}
