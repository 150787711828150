import { PageSizeItem } from "@progress/kendo-angular-grid";

export const environment = {
  production: false,
  apiUrl: 'api/',
  pwApiUrl: 'api/pw/',
  pmApiUrl: 'api/pm/',
  omsApiUrl: 'api/oms/',
  productApiUrl: 'api/products/',
  erpBaseUrl: 'https://ucgplt.apptrix.com',
  ecqBaseUrl: 'https://ucgecqlive.apptrix.com:612',
  configuratorBaseUrl: 'https://test.3d.ulrichlifestyle.com',
  apiGatewayBaseUrl: 'https://api.test.ulrichservice.com',
  inventoryUrl: 'https://test.inventory.ulrichlifestyle.com',
  pagerSettings: {
    pageSizes: [
      { text: 'All', value: 'all' },
      { text: '50', value: 50 },
      { text: '100', value: 100 },
      { text: '150', value: 150 },
      { text: '200', value: 200 }
    ] as PageSizeItem[],
    defaultPageSize: 100,
    buttonCount: 5
  },
  listTruckDeliveryDays: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  sourceTypes: [
    {name: 'Inventory', id: 'Inventory'},
    {name: 'Job', id: 'Job'},
    {name: 'Project', id: 'Project'}
  ],
  sourceTypeI: 'Inventory',
  sourceTypeJ: 'Job',
  sourceTypeK: 'Project',
  notification: {
    duration: 500
  },
  firstDay: 1,
  listNumberOfMltidays: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21],
  refreshTimeDP: 2,
  auth0: {
    domain: 'login.test.ulrichservice.com',
    clientId: 'xWntXttzul5Dmm99dGpNtavSdfovy2jW',

    audiences: {
      ooms: 'https://test.ulrichservice.com/api'
    }
  },
  sites: [ 'UBBCLE', 'MFGVA', 'MFGCA', 'MFGUHB' ],
  cocTemplateId: '7dc5db8b-6a10-47c0-a150-67ebd475634a',
  cocTemplateCustomId: '602fb1e2-7d92-4a91-905b-cf925143b9a9',
  moveTicketTemplateId: '2725b08e-e507-4b2e-abf5-00695ab0b4e3',
  hubspotAccountId: '21271738',
	authorizeNetAcceptJSSandboxUrl: 'https://jstest.authorize.net/v1/Accept.js',
  authorizeNetAcceptJSProdUrl: 'https://js.authorize.net/v1/Accept.js',
  companies: [
    {
      "site": "UBBCLE",
      "company": "Ulrich of TX, LLC",
      "market": "TX"
    },
    {
      "site": "MFGVA",
      "company": "Ulrich of VA, LLC",
      "market": "VA"
    },
    {
      "site": "MFGUHB",
      "company": "Ulrich Horse Barns, LLC",
      "market": ""
    },
    {
      "site": "MFGCA",
      "company": "Ulrich of CA, LLC",
      "market": "CA"
    }
  ],
  packetOrderDocId: 'd26bbc7e-e4c4-4cf8-ab36-abb06da9cbd7',
  defaultLocale: 'en-US',
  timezones: [
    {
      "site": "UBBCLE",
      "timezone": 'CST'
    },
    {
      "site": "MFGVA",
      "timezone": 'EST'
    },
    {
      "site": "MFGUHB",
      "timezone": 'CST'
    },
    {
      "site": "MFGCA",
      "timezone": 'PST'
    }
  ],
  warehouseLocations: [
    {
      "site": "UBBCLE",
      "lat": 32.37103435155787,
      "long": -97.35220655006549
    },
    {
      "site": "MFGVA",
      "lat": 38.385361485506536,
      "long": -78.92054803598867
    },
    {
      "site": "MFGCA",
      "lat": 35.22394774062308,
      "long": -118.8437454471013
    }
  ],
};
