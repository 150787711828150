<kendo-dialog title="Please confirm date" class="start-end-confirm-dialog">
  <form class="k-form" [formGroup]="formGroup">
    <div class="k-form-field">
      <span *ngIf="confirmFor === 'Released'">Actual start</span>
      <span *ngIf="confirmFor === 'Complete'">Actual end complete</span>
      <kendo-datepicker formControlName="startEndDate"
                        [format]="'MM/dd/yyyy'"
                        formatPlaceholder="formatPattern">
      </kendo-datepicker>
    </div>
  </form>
  <kendo-dialog-actions>
    <button kendoButton
            (click)="onConfirmAction()"
            themeColor="primary"
            [disabled]="!formGroup.valid">OK</button>
  </kendo-dialog-actions>
</kendo-dialog>
