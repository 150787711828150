import { Injectable } from '@angular/core';
import { toODataString, DataResult, State } from '@progress/kendo-data-query';
import { Observable, firstValueFrom, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Job } from '../models/job';
import { ProductionLine } from '../models/productionLine';
import { DateUtil } from '../utils/date.util';
import { BlackoutDay } from '../models/resourcePlan';
import { Serial } from '../models/serial';
import { environment } from 'src/environments/environment';

export const enum JobType {
  OnsiteKits = 'OnsiteKits',
  FactoryCabins = 'FactoryCabins',
  FactorySheds = 'FactorySheds',
}

export const enum ProductType {
  Shed = 'Shed',
  Cabin = 'Cabin',
  Deck = 'Deck',
}

export enum JobPlanStatus {
  Firm = 'Firm',
  Released = 'Released',
  KitComplete = 'KitComplete',
  Complete = 'Complete',
  Rework = 'Rework',
  Stopped = 'Stopped',
  Hold = 'Hold'
}

@Injectable({
  providedIn: 'root'
})
export class JobsService {

  constructor(private http: HttpClient) { }

  toUTCDate(date: Date): Date {
    return date ? DateUtil.toUTCDate(date) : null;
  }

  stringToLocalDate(dateStr: string | null): Date {
    return dateStr ? DateUtil.stringToLocalDate(dateStr) : null;
  }

  private localToRemote(job: Job): Job {
    return {
      id: job.id,
      suffix: job.suffix,
      jobPlan: job.jobPlan ? {
        ...job.jobPlan,

        startDate: this.toUTCDate(job.jobPlan.startDate),
        endDate: this.toUTCDate(job.jobPlan.endDate),
        actualStartDate: this.toUTCDate(job.jobPlan.actualStartDate),
        actualEndDate: this.toUTCDate(job.jobPlan.actualEndDate)
      } : null
    };
  }

  private blackoutDayRemoteToLocal(bl: BlackoutDay): BlackoutDay {
    if (bl) {
      bl.startDate  = DateUtil.toLocalDate(bl.startDate);
      bl.endDate    = DateUtil.toLocalDate(bl.endDate);
    }
    return bl;
  }

  private remoteToLocal(item: any): Job {
    return {
      ...item,
      startDate: this.stringToLocalDate(item.startDate),
      endDate: this.stringToLocalDate(item.endDate),

      orderItem: item.orderItem ? {
        ...item.orderItem,

        dueDate: this.stringToLocalDate(item.orderItem.dueDate),
        shipDate: this.stringToLocalDate(item.orderItem.shipDate),

        order: {
          ...item.orderItem.order,

          orderDate: this.stringToLocalDate(item.orderItem.order.orderDate),
          shipDate: this.stringToLocalDate(item.orderItem.order.shipDate),
          startDate: this.stringToLocalDate(item.orderItem.order.startDate),
          dueDate: this.stringToLocalDate(item.orderItem.order.dueDate)
        },

        itemPlan: item.orderItem.itemPlan ? {
          ...item.orderItem.itemPlan,
          startDate: this.stringToLocalDate(item.orderItem.itemPlan.startDate),
          endDate: this.stringToLocalDate(item.orderItem.itemPlan.endDate)
        } : null
      } : null,

      jobPlan: item.jobPlan ? {
        ...item.jobPlan,

        startDate: this.stringToLocalDate(item.jobPlan.startDate),
        endDate: this.stringToLocalDate(item.jobPlan.endDate),
        actualStartDate: this.stringToLocalDate(item.jobPlan.actualStartDate),
        actualEndDate: this.stringToLocalDate(item.jobPlan.actualEndDate)
      } : null
    } as Job;
  }

  public search(site: string, state: State): Observable<Job[]> {
    const queryStr = `${toODataString(state)}`;
    return this.http.get(`${environment.apiUrl}${site}/jobs?${queryStr}`).pipe(map(
      (response: DataResult) => {
        return response.data.map((job: any) => this.remoteToLocal(job));
      }
    ));
  }

  public searchAsync(site: string, state: State): Promise<Job[]> {
    return firstValueFrom(this.search(site, state));
  }

  public async getAsync(site: string, job: string, suffix: number): Promise<Job | undefined> {
    var jobs = await this.searchAsync(site, {
      filter: {
        logic: 'and',
        filters: [
          { field: 'job', operator: 'eq', value: job },
          { field: 'jobSuffix', operator: 'eq', value: suffix }
        ]
      },
      take: 1
    });
    return jobs?.shift();
  }

  public searchNewJob(site: string, state: State): Observable<Job[]> {
    const newState: State = {
      ...state,
      filter: {
        ...state.filter,
        filters: [
          ...state.filter.filters,
          { field: 'itemPlanStatus', operator: 'eq', value: 'Scheduled' },
          { field: 'jobPlanStatus', operator: 'neq', value: 'Firm' },
          { field: 'jobPlanStatus', operator: 'neq', value: 'Released' },
          { field: 'jobPlanStatus', operator: 'neq', value: 'KitComplete' },
          { field: 'jobPlanStatus', operator: 'neq', value: 'Complete' },
          { field: 'jobPlanStatus', operator: 'neq', value: 'Rework' },
          { field: 'jobPlanStatus', operator: 'neq', value: 'Stopped' },
          { field: 'jobPlanStatus', operator: 'neq', value: 'Hold' }
        ]
      },
      sort: [
        { field: 'itemPlanStartDate', dir: 'asc' }
      ]
    };
    return this.search(site, newState);
  }

  public searchOnHoldJob(site: string, state: State): Observable<Job[]> {
    const onHoldState: State = {
      ...state,
      filter: {
        ...state.filter,
        filters: [
          ...state.filter.filters,
          { field: 'jobPlanStatus', operator: 'eq', value: 'Hold' }
        ]
      },
      sort: [
        { field: 'itemPlanStartDate', dir: 'asc' }
      ]
    };
    return this.search(site, onHoldState);
  }

  public searchRecentlyCompleteJob(site: string, state: State): Observable<Job[]> {
    const recentlyCompleteState: State = {
      ...state,
      filter: {
        ...state.filter,
        filters: [
          ...state.filter.filters,
          {
            logic: 'or',
            filters: [
              { field: 'jobPlanStatus', operator: 'eq', value: 'KitComplete' },
              { field: 'jobPlanStatus', operator: 'eq', value: 'Complete' }
            ]
          }
        ]
      },
      sort: [
        { field: 'itemPlanStartDate', dir: 'asc' }
      ]
    };
    return this.search(site, recentlyCompleteState);
  }

  public searchPlanningJob(site: string, state: State): Observable<Job[]> {
    const planningJobState: State = {
      ...state,
      filter: {
        ...state.filter,
        filters: [
          ...state.filter.filters,
          {
            logic: 'or',
            filters: [
              { field: 'jobPlanStatus', operator: 'eq', value: 'Firm' },
              { field: 'jobPlanStatus', operator: 'eq', value: 'Released' },
              { field: 'jobPlanStatus', operator: 'eq', value: 'Rework' },
              { field: 'jobPlanStatus', operator: 'eq', value: 'Stopped' },
            ]
          }
        ]
      },
      sort: [
        { field: 'jobPlanPriority', dir: 'asc' }
      ]
    };
    return this.search(site, planningJobState);
  }

  public getPOStatus(site: string, req: Job[]): Observable<Job[]> {
    return this.http.post<Job[]>(`${environment.apiUrl}${site}/jobs/postatus`, req).pipe(map(
      (response: any) => {
        return response?.data ? response.data : [];
      }
    ));
  }

  public getAllJobTypes(getOnlyHorseBarn = false): Observable<any[]> {
    if (getOnlyHorseBarn) {
      return of([
        {
          id: 'FactoryHorseBarns',
          name: 'Factory HorseBarns'
        }
      ]);
    }

    return of([
      {
        id: 'FactorySheds',
        name: 'Factory Sheds'
      },
      {
        id: JobType.OnsiteKits,
        name: 'Onsite Kits'
      },
      {
        id: 'FactoryCabins',
        name: 'Factory Cabins'
      }
    ]);
  }

  public getAllProductionLines(): Observable<ProductionLine[]> {
    return this.http.get(`${environment.apiUrl}jobs/productionLines`).pipe(map(
      (response: any) => response
    ));
  }

  public viewInERP(site: string, job: Job) {
    const url = `${environment.erpBaseUrl}/WSWebClient/Default.aspx?`
      + `config=${site}&form=JobOrders(FILTER(Job='${job.id}' AND Suffix=${job.suffix})SETVARVALUES(InitialCommand=Refresh))`;
    window.open(url, '_blank');
  }

  public viewPickSheet(site: string, job: Job) {
    const url = `${environment.ecqBaseUrl}/csi9_${site}_App/webreports/Operations/Manufacturing/job_pick_sheet_multi/job_pick_sheet_multi.eq?cq-report&input_job=${job.id}`;
    window.open(url, '_blank');
  }

  public viewPoStatus(site: string, job: Job) {
    const url = `${environment.ecqBaseUrl}/csi9_${site}_App/webreports/Operations/Manufacturing/job_materials/job_materials.mf?input_job=${job.id}&input_due_date=ALL&input_source_type=Purchase%20Order&input_po_status=ALL`;
    window.open(url, '_blank');
  }

  public updateJobPlan(site: string, job: Job): Observable<Job> {
    const postJob = this.localToRemote(job);
    return this.http.post(`${environment.apiUrl}${site}/jobs/plans/${job.id}-${job.suffix}`, postJob).pipe(map(
      response => this.remoteToLocal(response)
    ));
  }

  public deleteJobPlan(site: string, job: Job): Observable<Job> {
    const postJob = this.localToRemote(job);
    return this.http.delete(`${environment.apiUrl}${site}/jobs/plans/${job.id}-${job.suffix}`).pipe(map(
      response => this.remoteToLocal(response)
    ));
  }

  public updateManyJobPlan(site: string, jobs: Job[]): Observable<any> {
    const postJobs = jobs.map(job => this.localToRemote(job));
    return this.http.post(`${environment.apiUrl}${site}/jobs/plans`, postJobs);
  }

  public getBlackoutDays(filter: {
    startDate: Date;
    endDate: Date;
  }): Observable<BlackoutDay[]> {
    const qstr = `${environment.apiUrl}blackoutDays?startDate=${filter.startDate.toISOString()}&endDate=${filter.endDate.toISOString()}`;
    return this.http.get(qstr).pipe(map(
      (response: any) =>  {
        return response.map(bl => this.blackoutDayRemoteToLocal(bl));
      }
    ));
  }

  public getSerials(site: string, job: Job): Observable<Serial[]> {
    const filter = `$filter=refNum eq '${job.id}' and refType eq 'J'`;
    return this.http.get(`${environment.apiUrl}${site}/serials?${filter}`)
      .pipe(map((reponse: any) => reponse));
  }

  public getNetPrices(site: string, jobs: Job[]): Observable<any[]> {
    jobs = jobs.map(job => {
      return {
        id: job.id,
        suffix: job.suffix
      }
    });
    return this.http.post<Job[]>(`${environment.apiUrl}${site}/jobs/netprices`, jobs);
  }

  public getNetPrice(site: string, job: string, suffix: number): Observable<number> {
    return this.getNetPrices(site, [{ id: job, suffix }]).pipe(map(r => r.shift()?.netPrice));
  }

  public getNetPriceAsync(site: string, job: string, suffix: number): Promise<number> {
    return firstValueFrom(this.getNetPrice(site, job, suffix));
  }
}
