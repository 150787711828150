import { Component, ViewChild, AfterViewInit, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { DatePickerComponent } from '@progress/kendo-angular-dateinputs';
import { FilterOperatorComponent } from '../filter-operator.component';
import { FilterDateDescriptor } from './filter-date-descriptor';
import { FilterDescriptorEx, CompositeFilterDescriptorEx } from '../ooms-filter-descriptor';

const ToValidator: ValidatorFn = (fg: FormGroup) => {
  const operator = fg.get('operator').value;
  if (operator === 'bt') {
    const value2 = fg.get('value2').value;
    return value2 ? null : { toValueInvalid: true };
  }
  return null;
};

@Component({
  selector: 'filter-date',
  templateUrl: './filter-date.component.html',
  styleUrls: ['./filter-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterDateComponent extends FilterOperatorComponent<FilterDateDescriptor> implements AfterViewInit {
  @ViewChild('valuePicker1') public valuePicker1: DatePickerComponent;

  // Equals
  // ---------------------------------
  // Before
  // After
  // Between
  // ---------------------------------
  // Today
  // Yesterday
  // Last 7 days
  // Last 30 days
  // Last 45 days
  // Last 90 days
  // ---------------------------------
  // Last Week
  // Last Month
  // Last Year
  // ---------------------------------
  // Week to Date
  // Month to Date
  // Quarter To Date
  // Year To Date
  readonly operators = [
    {
      text: 'Equals',
      value: 'eq'
    },
    {
      disabled: true
    },
    {
      text: 'Before',
      value: 'lt'
    },
    {
      text: 'After',
      value: 'gt'
    },
    {
      text: 'Between',
      value: 'bt'
    }
  ];

  constructor(private fb: FormBuilder) {
    super();
  }

  init() {
    const current = new Date();
    this.formGroup = this.fb.group(
      {
        operator: [this.defaultOperator || 'eq', Validators.required],
        value1: [
          this.defaultFilterValue(0) || new Date(current.getFullYear(), current.getMonth(), current.getDate()),
          Validators.required
        ],
        value2: [this.defaultFilterValue(1) || new Date(current.getFullYear(), current.getMonth(), current.getDate())]
      },
      {
        validators: [ToValidator]
      }
    );
  }

  ngAfterViewInit() {
    window.setTimeout(() => {
      this.valuePicker1.focus();
    });
  }

  public get value(): FilterDescriptorEx | CompositeFilterDescriptorEx {
    const operator = this.formGroup.get('operator').value as string;
    const value1 = this.formGroup.get('value1').value as Date;
    const value2 = this.formGroup.get('value2').value as Date;
    return this.descriptor.createFilter(operator, value1, value2);
  }

  public changeOperator(op: string) {
    window.setTimeout(() => {
      this.valuePicker1.focus();
    });
  }
}
