<div class="filter-tags">
  <kendo-chip *ngFor="let filter of filters" class="filter-tag" [removable]="true" (remove)="clearFilter.emit(filter)" (click)="filterEdit.emit(filter)">
    
    <!-- {{ callThisForTesting(filters) }} -->
    
    <span class="filter-title">{{ getTitle(filter) }}{{ getOperation(filter) }}</span>
    <span class="filter-value">{{ getValue(filter) }}</span>
    <!-- <span *ngIf="showCount(filter)" class="filter-value-count">{{ getCount(filter) }}</span> -->
  </kendo-chip>
  <button *ngIf="filters.length > 1" kendoButton class="filter-tag" (click)="clearAllFilter.emit()" [fillMode]="'flat'">Clear All</button>
</div>
