import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataSourceRequestState, DataResult, toODataString } from '@progress/kendo-data-query';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User } from '../models/user';
import {UserProfile} from "../models/user-profile";

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  static CONST = {
    Status: {
      active: {
        value: 'Active',
        desc: 'Active'
      },
      blocked: {
        value: 'Blocked',
        desc: 'Blocked'
      }
    }
  };

  constructor(private http: HttpClient) { }

  public get(site: string, id: string): Observable<User> {
    return this.http.get(`${environment.apiUrl}${site}/users/${id}`).pipe(map(
      (response: any) => response
    ));
  }

  public create(site: string, user: User): Observable<User> {
    return this.http.post<User>(`${environment.apiUrl}${site}/users`, user);
  }

  public update(site: string, user: User): Observable<User> {
    return this.http.put<User>(`${environment.apiUrl}${site}/users/${user.id}`, user);
  }

  public delete(site: string, user: User): Observable<any> {
    return this.http.delete<User>(`${environment.apiUrl}${site}/users/${user.id}`);
  }

  public deleteMany(site: string, users: User[]): Observable<any> {
    return this.http.request('delete', `${environment.apiUrl}${site}/users`, {
      headers: {
        ['Content-Type']: 'application/json'
      },
      body: users
    });
  }

  public fetch(site: string, state: DataSourceRequestState): Observable<DataResult> {
    const queryStr = `${toODataString(state)}`; // Serialize the state
    return this.http.get(`${environment.apiUrl}${site}/users?${queryStr}`).pipe(map(
      (response: any) => ({
        data: response.data,
        total: response.total
      } as DataResult)
    ));
  }

  public getProfile(userId: string): Observable<UserProfile> {
    return this.http.get<User>(`${environment.apiUrl}users/${userId}/profile`);
  }
}
