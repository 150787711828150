import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataResult, toODataString, State } from '@progress/kendo-data-query';
import { QueryUtil } from '../../shared/utils/query.util';
import { DateUtil } from '../utils/date.util';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ResourcesService {

  constructor(private http: HttpClient) { }

  public getEquipmentResources(site: string, values: string[] | string): Observable<DataResult> {
    const valuesQueryStr = QueryUtil.genQueryValueArrays('equipmentPrimaryType', values);
    const activeEquipmentCondition = `status eq 'Active'`;
    const queryStr = `$filter=type eq 'Equipment' and ${valuesQueryStr} and ${activeEquipmentCondition}`;
    return this.http.get(`${environment.apiUrl}${site}/resources?${queryStr}`).pipe(map(
      (response: any) => ({
        data: response.data,
        total: response.total
      } as DataResult)
    ));
  }


  public getOutOfServices(site: string, state: State): Observable<DataResult> {
    const qstr = `${toODataString(state, {utcDates: true})}`;
    return this.http.get(
      `${environment.apiUrl}${site}/outOfServices?${qstr}`
    ).pipe(map(
      (response: any) => ({
        data: response.data.map(item => {
          if (item.startDate) {
            item.startDate  = DateUtil.toLocalDateTime(item.startDate);
          }
          if (item.endDate) {
            item.endDate    = DateUtil.toLocalDateTime(item.endDate);
          }
          return item;
        }),
        total: response.total
      } as DataResult)
    ));
  }
}
