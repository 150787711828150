import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Person } from '../models/person';
import { PersonUser } from '../models/person-user';
import { Site } from '../models/site';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private http: HttpClient) { }

  public current(): Observable<User> {
    return this.http.get<User>(`${environment.apiUrl}account`);
  }

  public currentAsync(): Promise<User> {
    return firstValueFrom(this.current());
  }

  public currentPerson(site: string): Observable<PersonUser> {
    return this.http.get<PersonUser>(`${environment.apiUrl}${site}/account/person`);
  }

  public sites(): Observable<Site[]> {
    return this.http.get<Site[]>(`${environment.apiUrl}account/sites`);
  }

  public operations(site: string): Observable<string[]> {
    return this.http.get<string[]>(`${environment.apiUrl}${site}/account/operations`);
  }

  public getStores(userId: string): Observable<string[]> {
    return this.http.get<string[]>(`${environment.apiUrl}account/${userId}/stores`);
  }
}
