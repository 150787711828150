<form [formGroup]="filterInputFormGroup" class="address-dialog">
    <div class="filter-input">
        <input kendoTextBox #textbox formControlName="filter-input" placeholder="Search" />
        <span class="k-icon k-i-search"></span>
    </div>
</form>

<kendo-grid [data]="filteredData | async"
            [selectable]="{
              enabled: true,
              mode: 'multiple'
            }"
            [pageable]="false"
            [sortable]="false"
            [filterable]="false"
            [(selectedKeys)]="selected"
            [loading]="loading"
            kendoGridSelectBy="value"
            [scrollable]="'scrollable'"
            [style.maxHeight.px]="216"> <!--36*6=5 rows height plus header-->
  <kendo-grid-checkbox-column [width]="40" [showSelectAll]="true"></kendo-grid-checkbox-column>
  <kendo-grid-column field="title" title="Select All">
    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
      {{ dataItem.title ? dataItem.title : dataItem.value }}
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
