import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SelectorService {

  constructor(@Inject(DOCUMENT) private document: Document) { }

  public docReady(_unsubscribe: Subject<void>, fn) {
		if (document.readyState === "complete" || document.readyState === "interactive") {
			setTimeout(fn, 1);
		} else {
			fromEvent(document, 'DOMContentLoaded').pipe(
				takeUntil(_unsubscribe)
			).subscribe((e: Event) => {
				fn();
			});
		}
	}

	public addEvent(el, type, fn) {
		if (el.attachEvent) el.attachEvent('on' + type, fn); else el.addEventListener(type, fn);
	}

	public removeEvent(el, type, fn) {
		if (el.detachEvent) el.detachEvent('on' + type, fn); else el.removeEventListener(type, fn);
	}

	public findFn(selector, fn) {
		var selectors = document.querySelectorAll(selector);
		[].forEach.call(selectors, fn);
	}

	public hasClass(el, cls) {
		if (!el) {
			return false;
		}
		return !!el.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'));
	}

	public addClass(el, cls) {
		if (!this.hasClass(el, cls)) el.className += " " + cls;
	}

	public removeClass(el, cls) {
		if (this.hasClass(el, cls)) {
			var reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
			el.className = el.className.replace(reg, ' ');
		}
	}
}
