import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { OperationService } from 'src/app/core/services/operation.service';

@Injectable({
  providedIn: 'root'
})
export class UserOperationsResolver implements Resolve<string[]> {
  constructor(private operationService: OperationService) { }

  resolve(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<string[]> {
    return this.operationService.operations.pipe(first());
  }
}
