<mat-toolbar color="primary" class="header">
  <span>
    <button
      #openedToggle
      class="toggle-header"
      mat-icon-button
      id="menu"
      (click)="openNav()">
      <mat-icon>menu</mat-icon>
    </button>
  </span>
  <span class="header-label"><app-breadcrumb></app-breadcrumb></span>
  <div class="header-control">
    <div class="input-icon">
      <kendo-autocomplete
        #customerSearch
        [data]="quickSearchResult"
        [valueField]="'id'"
        [filterable]="true"
        (filterChange)="searchByCustomerName()"
        (valueChange)="viewDetail($event)"
        [suggest]="true"
        [placeholder]="'e.g. Customer name ...'"
        [popupSettings]="{ width: 440 }"
        class="customerSearch"
      >
        <ng-template kendoAutoCompleteHeaderTemplate *ngIf="quickSearchResult && quickSearchResult.length > 0">
          <span>WE FOUND {{quickSearchResult.length}} RESULT(S)</span>
        </ng-template>
        <ng-template kendoAutoCompleteItemTemplate let-dataItem>
          <div kendoTooltip class="right" position="right" *ngIf="[FILTERS_QUICKSEARCH.OrderNumber, FILTERS_QUICKSEARCH.JobNumber, FILTERS_QUICKSEARCH.ProjectNumber, FILTERS_QUICKSEARCH.QuoteNum].includes(dataItem.cat2)">
            <span>
              <span class="customer-search-popup-body">{{ dataItem.text }}</span> in <span class="customer-search-popup-body">{{ dataItem.displayedType }} {{dataItem.displayedSubType}}</span>
            </span>
          </div>
          <div kendoTooltip class="right" position="right" *ngIf="[FILTERS_QUICKSEARCH.ContactEmail, FILTERS_QUICKSEARCH.ContactName, FILTERS_QUICKSEARCH.ContactPhone, FILTERS_QUICKSEARCH.LeadName, FILTERS_QUICKSEARCH.LeadEmail, FILTERS_QUICKSEARCH.LeadPhone].includes(dataItem.cat2)">
            <span>
              <span class="customer-search-popup-body">{{ dataItem.cat4 }}</span>
              <span *ngIf="dataItem.cat4" class="customer-search-popup-body">&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span class="customer-search-popup-body">{{ dataItem.groupPhone }}</span>
              <br *ngIf="dataItem.cat5">
              <span *ngIf="dataItem.cat5" class="customer-search-popup-body">{{ dataItem.cat5 }}</span> in <span class="customer-search-popup-body">{{ dataItem.displayedType }} {{dataItem.displayedSubType}}</span>
            </span>
          </div>
        </ng-template>
      </kendo-autocomplete>
      <i class="material-icons">search</i>
    </div>
    <div class="header-site">
      <i class="material-icons display-flex material-icons-extra">factory</i>
      <span class="input-icon">
        <kendo-dropdownlist [data]="sites | async"
                            [textField]="'description'"
                            [valueField]="'id'"
                            [valuePrimitive]="true"
                            [ngModel]="selectedSite | async"
                            (valueChange)="changeSite($event)"></kendo-dropdownlist>
      </span>
    </div>
    <app-account-menu></app-account-menu>
  </div>
</mat-toolbar>
