import { LOG_TYPE } from "src/app/app.constant";
import { environment } from "src/environments/environment";

export class DateUtil {

    static toLocalDate(date: Date): Date {
        date = new Date(date);
        const newdate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
        return newdate;
    }

    static stringToLocalDate(dateStr: string | null): Date {
      if (dateStr) {
        const parsedDate = Date.parse(dateStr);
        if (isNaN(parsedDate)) {
          return null;
        }
        const date = new Date(parsedDate);
        return DateUtil.toLocalDate(date);
      }
      return null;
    }

    static stringToUTCDate(dateStr: string | null): Date {
      if (dateStr) {
        const parsedDate = Date.parse(dateStr);
        if (isNaN(parsedDate)) {
          return null;
        }
        const date = new Date(dateStr);
        return DateUtil.toLocalDate(date);
      }
      return null;
    }

    static toLocalDateTime(date: Date): Date {
        date = new Date(date);
        const newdate = new Date(
            date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()
        );
        return newdate;
    }

    static stringToLocalDateTime(dateStr: string): Date {
      const parsedDate = Date.parse(dateStr);
      if (isNaN(parsedDate)) {
        return null;
      }
      return new Date(parsedDate);
    }

    /** @deprecated **/
    static toUTCDate(date: Date): Date {
      if (date) {
        const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
        return utcDate;
      }
      return null;
    }

    static toUTCDateMidday(date: Date): Date {
      if (date) {
        let utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 12, 0, 0, 0));
        return utcDate;
      }
      return null;
    }

    static toUTCDateEndday(date: Date): Date {
      if (date) {
        let utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999));
        return utcDate;
      }
      return null;
    }

    static toUTCDateTime(date: Date): Date {
        date = new Date(date);
        const utcDate = new Date(
            Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds())
        );
        return utcDate;
    }

    static buildListDateByRange(startDate, endDate) {
      const dates = [];
      let currentDate = startDate;
      const addDays = function(days) {
        const date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
      };
      while (currentDate <= endDate) {
        dates.push(currentDate);
        currentDate = addDays.call(currentDate, 1);
      }
      return dates;
    }

    static jsonDateParser(key, value) {
      const reISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;
      const reMsAjax = /^\/Date\((d|-|.*)\)[\/|\\]$/;
      if (typeof value === 'string') {
          let a = reISO.exec(value);
          if (a) {
            return new Date(value);
          }
          a = reMsAjax.exec(value);
          if (a) {
              const b = a[1].split(/[-+,.]/);
              return new Date(b[0] ? +b[0] : 0 - +b[1]);
          }
      }
      return value;
    }

    static localDateToLocalISOString(date: Date) {
      return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000).toISOString().slice(0, -1);
    }

    static getString(date: Date) {
      var mm = date.getMonth() + 1;
      var dd = date.getDate();

      return [(mm>9 ? '' : '0') + mm,
        (dd>9 ? '' : '0') + dd,
        date.getFullYear()
      ].join('/');
    }

    static getTimezoneBySite(site: string) {
      return environment.timezones.find(t => t.site == site)?.timezone;
    }

    static getFormatDatetimeToParts(date: Date, timeZone, locale = 'en-US', option = null):any {
      option = option || {
        year:'numeric',
        month:'2-digit',
        day:'2-digit',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
        weekday: "short",
        timeZone
      };
      return new Intl.DateTimeFormat(locale, option).formatToParts(date).reduce((acc, part) => {
          acc[part.type] = part.value;
          return acc;
      }, {});
    }

    static formatLogikDate(date: Date) {
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      const day = String(date.getUTCDate()).padStart(2, '0');
      return `${year}${month}${day}`;
    }

    static isDate(date: string) {
      if (!isNaN(Number(date)) || (date.length > 0 && isNaN(Number(date.charAt(0)))) || isNaN(Date.parse(date))) {
        return false;
      }
      return true;
    }

    static getCommonLocalDateBySite(date: Date, site: string, combineTime = false, combineWeekday = false) {
      let timeZone = DateUtil.getTimezoneBySite(site);
      let timeParts = DateUtil.getFormatDatetimeToParts(date, timeZone);
      let rs = `${timeParts?.month}/${timeParts?.day}/${timeParts?.year}`;
      if (combineTime) {
        rs = `${rs} ${timeParts?.hour}:${timeParts?.minute}:${timeParts?.second}`;
      }

      if (combineWeekday) {
        rs = `${timeParts?.weekday} ${rs}`;
      }

      return rs;
    }
}
