import { UpdateResourcePlanDTO, HoldReason } from 'src/app/core/models/resourcePlan';
import { BlackoutDay, ItemPlan} from '../models/resourcePlan';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataResult, toODataString, State } from '@progress/kendo-data-query';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResourcePlan } from '../models/resourcePlan';
import { OrderItem } from '../models/orderItem';
import { DateUtil } from '../utils/date.util';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class DeliveryPlannerService {

  constructor(private http: HttpClient) { }

  public localToRemoteResourcePlan(item: ResourcePlan): ResourcePlan {
    if (!item) { return item; }
    if (item.startDate) {
      item.startDate  = DateUtil.toUTCDateTime(item.startDate);
    }
    if (item.endDate) {
      item.endDate    = DateUtil.toUTCDateTime(item.endDate);
    }
    item.resourceLoads = item.resourceLoads || [];
    item.resourceLoads = item.resourceLoads.map( rl => {
      rl.itemPlans = rl.itemPlans || [];
      rl.itemPlans = rl.itemPlans.map( ip => {
        ip.startDate  = DateUtil.toUTCDateTime(ip.startDate);
        ip.endDate    = DateUtil.toUTCDateTime(ip.endDate);
        return ip;
      });
      return rl;
    });
    return item;
  }

  public remoteToLocalResourcePlan(item: ResourcePlan): ResourcePlan {
    if (!item) { return item; }
    if (item.startDate) {
      item.startDate  = DateUtil.toLocalDateTime(item.startDate);
    }
    if (item.endDate) {
      item.endDate    = DateUtil.toLocalDateTime(item.endDate);
    }
    item.resourceLoads = item.resourceLoads || [];
    item.resourceLoads = item.resourceLoads.map( rl => {
      rl.itemPlans = rl.itemPlans || [];
      rl.itemPlans = rl.itemPlans.map( ip => {
        ip.startDate  = DateUtil.toLocalDateTime(ip.startDate);
        ip.endDate    = DateUtil.toLocalDateTime(ip.endDate);
        if (ip.orderItem && ip.orderItem.job && ip.orderItem.job.jobPlan) {
          ip.orderItem.job.jobPlan.startDate  = DateUtil.toLocalDateTime(ip.orderItem.job.jobPlan.startDate);
          ip.orderItem.job.jobPlan.endDate    = DateUtil.toLocalDateTime(ip.orderItem.job.jobPlan.endDate);
        }
        return ip;
      });
      return rl;
    });
    return item;
  }
  private blackoutDayRemoteToLocal(bl: BlackoutDay): BlackoutDay {
    if (bl) {
      bl.startDate  = DateUtil.toLocalDate(bl.startDate);
      bl.endDate    = DateUtil.toLocalDate(bl.endDate);
    }
    return bl;
  }
  public getResourcePlans(site: string, state: State): Observable<DataResult> {
    const qstr = `${toODataString(state, {utcDates: true})}`;
    const url = `${environment.apiUrl}${site}/resourcePlans?${qstr}`;
    return this.http.get(url).pipe(map(
      (response: any) => ({
        data: response.data.map(item => this.remoteToLocalResourcePlan(item)),
        // data: response.data,
        total: response.total
      } as DataResult)
    ));
  }

  public updateTimeLine(site: string, updateResourcePlanDTO: UpdateResourcePlanDTO): Observable<any> {
    if (updateResourcePlanDTO.updateResourcePlans) {
      updateResourcePlanDTO.updateResourcePlans = updateResourcePlanDTO.updateResourcePlans.map(rp => {
        return this.localToRemoteResourcePlan(rp);
      });
    }
    if (updateResourcePlanDTO.itemPlans && updateResourcePlanDTO.itemPlans.length) {
      updateResourcePlanDTO.itemPlans = updateResourcePlanDTO.itemPlans.map(rp => {
        rp.startDate = DateUtil.toUTCDateTime(rp.startDate);
        rp.endDate = DateUtil.toUTCDateTime(rp.endDate);
        return rp;
      });
    }
    return this.http.post<ResourcePlan[]>(`${environment.apiUrl}${site}/resourcePlans/save`, updateResourcePlanDTO).pipe(map(
      (response: any) => response
    ));
  }

  public getBlackoutDays(filter: {
    startDate: Date;
    endDate: Date;
  }): Observable<BlackoutDay[]> {
    const qstr = `${environment.apiUrl}blackoutDays?startDate=${filter.startDate.toISOString()}&endDate=${filter.endDate.toISOString()}`;
    return this.http.get(qstr).pipe(map(
      (response: any) =>  {
        // return response;
        return response.map(bl => this.blackoutDayRemoteToLocal(bl));
      }
    ));
  }

  public searchOrderItems(site: string, state: State): Observable<OrderItem[]> {
    const qstr = `${toODataString(state, {utcDates: true})}`;
    return this.http.get<OrderItem[]>(`${environment.apiUrl}${site}/orderItems?${qstr}`).pipe(map(
      (response: any) => response
    ));
  }

  public updateItemPlans(site: string, itemPlans: ItemPlan[]): Observable<any> {
    return this.http.put<ItemPlan[]>(`${environment.apiUrl}${site}/resourcePlans/itemPlans`, itemPlans).pipe(map(
      (response: any) => response
    ));
  }

  public getItemPlanByOrderItemInfo(site: string, orderInfo: { num: string; line: number; release: number; }): Observable<ItemPlan> {
    const url = `${environment.apiUrl}${site}/OrderItems/${orderInfo.num}-${orderInfo.line}-${orderInfo.release}`;
    return this.http.get<ItemPlan>(url).pipe(map(
      (response: any) => response
    ));
  }

  public getHoldReason(): Observable<HoldReason[]> {
    return this.http.get(`${environment.apiUrl}resourcePlans/delivery-hold-reasons`).pipe(map(
      (response: HoldReason[]) => response
    ));
  }
}
