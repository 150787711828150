<div class="brand-item">
  <a class="brand-logo"></a>
  <button
    #closedToggle
    class="brand-toggle"
    mat-icon-button
    id="brand-toggle"
    (click)="closeNav()"
  >
  <mat-icon class="material-icons-extra toggle-open">menu_open</mat-icon>
  </button>
</div>
<mat-nav-list>
  <app-menu-list-item
    *ngFor="let item of navItems"
    [item]="item"
  ></app-menu-list-item>
</mat-nav-list>
