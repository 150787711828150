import { Warehouse } from 'src/app/core/models/warehouse';
import { Pipe, PipeTransform } from '@angular/core';
import { Customer } from './../../core/models/customer';

@Pipe({
  name: 'customerAddress'
})
export class CustomerAddressPipe implements PipeTransform {
  ADDRESS_SEPARATOR = '-';

  transform(obj: Customer | Warehouse, args?: any): any {
    if (!obj) {
      return null;
    }
    const streetAddressFull: string = [obj.address1, obj.address2, obj.address3, obj.address4]
      .filter((el) => el != null && el.trim() !== '')
      .join(this.ADDRESS_SEPARATOR);
    return streetAddressFull;
  }
}
