<form [formGroup]="formGroup" class="k-form container">
  <div class="k-form-field">
    <kendo-dropdownlist formControlName="value"
                        [data]="operators"
                        [itemDisabled]="itemDisabled"
                        [textField]="'text'"
                        [valueField]="'value'"
                        [valuePrimitive]="true"
                        [popupSettings]="{ popupClass: 'group-items' }">
    </kendo-dropdownlist>
  </div>
</form>
