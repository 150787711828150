import { Pipe, PipeTransform } from '@angular/core';
import { Project } from 'src/app/core/models/project';
import { Serial } from '../../core/models/serial';
import { AbstractSerialPipe } from './AbstractSerialPipe';

@Pipe({
  name: 'projectSerial'
})
export class ProjectSerialPipe extends AbstractSerialPipe implements PipeTransform {
  transform(project: Project, args?: any): string {
    if (!project) {
      return null;
    }

    const serial: Serial = this.getProjectSerialNoWidthLenght(project);
    return serial ? serial.serNum : '';
  }
}
