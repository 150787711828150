import { Injectable } from '@angular/core';
import { NotificationService as NS, NotificationSettings, NotificationRef } from '@progress/kendo-angular-notification';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private notificationService: NS) {
  }

  public default(msg: string) {
    this.notificationService.show({
      content: msg,
      animation: { type: 'fade', duration: environment.notification.duration },
      position: { horizontal: 'center', vertical: 'top' },
      type: { style: 'none', icon: false }
    });
  }

  public success(msg: string) {
    this.notificationService.show({
      content: msg,
      animation: { type: 'fade', duration: environment.notification.duration },
      position: { horizontal: 'center', vertical: 'top' },
      type: { style: 'success', icon: true }
    });
  }

  public warning(msg: string) {
    this.notificationService.show({
      content: msg,
      animation: { type: 'fade', duration: environment.notification.duration },
      position: { horizontal: 'center', vertical: 'top' },
      type: { style: 'warning', icon: true }
    });
  }

  public info(msg: string, hideAfter = 0, duration = 0): NotificationRef {
    hideAfter = hideAfter || 5000;
    duration = duration || environment.notification.duration;
    return this.notificationService.show({
      content: msg,
      hideAfter,
      animation: { type: 'fade', duration },
      position: { horizontal: 'center', vertical: 'top' },
      type: { style: 'info', icon: true }
    });
  }

  public error(msg: string) {
    this.notificationService.show({
      hideAfter: 10000,
      content: msg,
      animation: { type: 'fade', duration: environment.notification.duration },
      position: { horizontal: 'center', vertical: 'top' },
      type: { style: 'error', icon: true }
    });
  }

  public show(settings: NotificationSettings): NotificationRef {
    return this.notificationService.show(settings);
  }
}
