<mat-toolbar color="primary" class="header toolbar-common">
  <div class="mg-right">
      <span flex></span>
      <span *ngIf="showSaveButton | async">
        <button kendoButton
                class="save-btn"
                themeColor="primary"
                (click)="clickSave($event)"
                [disabled]="disabledSaveButton | async">
          <i class="material-icons display-flex">save</i>{{saveLabel}}
        </button>
      </span>
      <span *ngIf="showCancelbutton | async">
        <button class="cancel-btn" kendoButton (click)="clickCancel($event)">{{cancelLabel}}</button>
      </span>
  </div>
</mat-toolbar>
