import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-start-end-confirm-dialog',
  templateUrl: './start-end-confirm-dialog.component.html',
  styleUrls: ['./start-end-confirm-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StartEndConfirmDialogComponent extends DialogContentBase implements OnInit {
  date: Date;
  confirmFor: string;
  formGroup: FormGroup;

  constructor(dialog: DialogRef, private fb: FormBuilder) {
    super(dialog);
  }

  get f() {
    return this.formGroup.controls;
  }

  public get value(): Date {
    return this.formGroup.get('startEndDate').value as Date;
  }

  ngOnInit() {
    this.formGroup = this.fb.group({
      startEndDate: [ this.date, Validators.required ]
    });
  }

  public onConfirmAction(): void {
    this.dialog.close({ primary: true, themeColor: 'primary' });
  }
}
