import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ooservice-confirm-dialog',
  templateUrl: './ooservice-confirm-dialog.component.html',
  styleUrls: ['./ooservice-confirm-dialog.component.scss']
})
export class OoserviceConfirmDialogComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
