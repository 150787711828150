import { OrderItem } from './../models/orderItem';
import { Serial } from '../models/serial';
import { Job } from '../models/job';
import { StockOrder } from '../models/stock-order';
import { Warehouse } from '../models/warehouse';
import { Item } from '../models/item';
import { ReturnType } from '../models/return-type';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class StockOrderService {
  private topItems = 10000;

  constructor(private httpClient: HttpClient) { }

  public getOrderDetail(site: string, orderNum: string): Observable<StockOrder> {
    if (!orderNum) {
      return of(null);
    }
    return this.httpClient
      .get(`${environment.apiUrl}${site}/orders/${orderNum}`)
      .pipe(map((response: any) => response));
    // return this.httpClient
    //   .get(`https://demo6458973.mockable.io/orders-prefill`)
    //   .pipe(map((response: any) => response));
  }

  public getSourceTypes(): Observable<ReturnType[]> {
    return this.httpClient.get<ReturnType[]>(`${environment.apiUrl}orders/returnTypes?$top=${this.topItems}`);
    // return this.httpClient.get(`https://demo6458973.mockable.io/returnTypes`).pipe(map((response: any) => response));
  }

  public getItems(site: string): Observable<Item[]> {
    return this.httpClient
      .get(`${environment.apiUrl}${site}/items?$top=${this.topItems}`)
      .pipe(map((reponse: any) => reponse.map((res: any) => res)));
    // return this.httpClient
    //   .get(`https://demo6458973.mockable.io/items`)
    //   .pipe(map((reponse: any) => reponse.map((res: any) => res)));
  }

  public getWarehouses(site: string): Observable<Warehouse[]> {
    return this.httpClient
      .get(`${environment.apiUrl}${site}/whses?$top=${this.topItems}`)
      .pipe(map((reponse: any) => reponse.map((res: any) => res)));
    // return this.httpClient
    //   .get(`https://demo6458973.mockable.io/whses`)
    //   .pipe(map((reponse: any) => reponse.map((res: any) => res)));
  }

  public getWarehouseDetail(site: string, whseCode: string): Observable<Warehouse> {
    return this.httpClient
      .get(`${environment.apiUrl}${site}/whses/${whseCode}`)
      .pipe(map((reponse: any) => reponse));
    // return this.httpClient.get(`https://demo6458973.mockable.io/whse-detail`).pipe(map((reponse: any) => reponse));
  }

  public getJobs(site: string): Observable<Job[]> {
    // TODO filter
    const filter = `$filter=jobOrdType eq 'I' and jobStatus ne 'Complete' and jobStatus ne 'History' and jobStatus ne 'Stopped' and orderType ne 'Stock' and orderType ne 'Return'`;
    // const filter = `$filter=jobStatus ne 'Complete' and orderType ne 'Stock' and orderType ne 'Return'`;
    // TODO filter by jobOrdType also
    return this.httpClient
      .get(`${environment.apiUrl}${site}/jobs?${filter}&$top=${this.topItems}`)
      .pipe(map((reponse: any) => reponse.data));
  }

  public getSerials(site: string, item: string, whse: string): Observable<Serial[]> {
    // TODO filter
    const filter = `$filter=item eq '${item}' and warehouse eq '${whse}' and status eq 'I'`;
    return this.httpClient
      .get(`${environment.apiUrl}${site}/serials?${filter}&$top=${this.topItems}`)
      .pipe(map((reponse: any) => reponse));
  }

  public getJobSerial(site: string, jobId: string): Observable<Serial> {
    // TODO filter
    const filter = `$filter=refType eq 'J' and refNum eq '${jobId}'`;
    return this.httpClient.get(`${environment.apiUrl}${site}/serials?${filter}&$top=${this.topItems}`).pipe(
      map((response: any) => {
        return response && response.length > 0 ? response[0] : null;
      })
    );
  }

  public getSerial(site: string, serNum: string): Observable<Serial> {
    // TODO filter
    const filter = `$filter=serNum eq '${serNum}'`;
    return this.httpClient.get(`${environment.apiUrl}${site}/serials?${filter}&$top=${this.topItems}`).pipe(
      map((response: any) => {
        return response && response.length > 0 ? response[0] : null;
      })
    );
  }

  public getOrderItemsBySerial(site: string, serial: string): Observable<OrderItem[]> {
    // TODO filter
    const filter = `$filter=itemSerialNumber eq '${serial}' and itemPlanStatus ne 'Shipped' and itemPlanStatus ne 'Complete'`;
    return this.httpClient
      .get(`${environment.apiUrl}${site}/orderItems?${filter}&$top=${this.topItems}`)
      .pipe(map((reponse: any) => reponse));
  }

  public create(site: string, returnOrder: StockOrder): Observable<StockOrder> {
    return this.httpClient.post<StockOrder>(`${environment.apiUrl}${site}/orders`, returnOrder);
  }

  public update(site: string, orderNum: string, returnOrder: StockOrder): Observable<StockOrder> {
    return this.httpClient.put<StockOrder>(`${environment.apiUrl}${site}/orders/${orderNum}`, returnOrder);
  }
}
