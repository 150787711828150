import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataSourceRequestState, DataResult, toODataString } from '@progress/kendo-data-query';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Group } from '../models/group';
import { Operation } from '../models/operation';
import { environment } from 'src/environments/environment';
import { Profile } from '../models/profile';

@Injectable({
  providedIn: 'root'
})
export class GroupsService {
  static GroupConst = {
    Scopes: {
      user: {
        group: 'user',
        views: {
          group: 'User',
          create: 'Create User',
          update: 'Update User',
          delete: 'Delete User'
        },
        scopes: {
          group: 'user',
          create: 'user.create',
          update: 'user.update',
          delete: 'user.delete'
        }
      },
      order: {
        group: 'order',
        views: {
          group: 'Order',
          create: 'Create Order',
        },
        scopes: {
          group: 'order',
          create: 'order.create',
        }
      }
    }
  };

  constructor(private http: HttpClient) { }

  public get(site: string, id: string): Observable<Group> {
    return this.http.get(`${environment.apiUrl}${site}/groups/${id}`).pipe(map(
      (response: any) => response as Group
    ));
  }

  public create(site: string, group: Group): Observable<Group> {
    return this.http.post<Group>(`${environment.apiUrl}${site}/groups`, group).pipe(map(
      (response: any) => response as Group
    ));
  }

  public update(site: string, group: Group): Observable<Group> {
    return this.http.put<Group>(`${environment.apiUrl}${site}/groups/${group.id}`, group);
  }

  public delete(site: string, group: Group): Observable<any> {
    return this.http.delete<Group>(`${environment.apiUrl}${site}/groups/${group.id}`);
  }

  public deleteMany(site: string, groups: Group[]): Observable<any> {
    return this.http.request('delete', `${environment.apiUrl}${site}/groups`, {
      headers: {
        ['Content-Type']: 'application/json'
      },
      body: groups
    });
  }

  public getAllOperations(): Observable<Operation[]> {
    return this.http.get(`${environment.apiUrl}groups/operations`).pipe(map(
      (response: any) => response
    ));
  }

  public fetch(site: string, state: DataSourceRequestState): Observable<DataResult> {
    const queryStr = `${toODataString(state)}`; // Serialize the state
    return this.http.get(`${environment.apiUrl}${site}/groups?${queryStr}`).pipe(map(
      (response: any) => ({
        data: response.data,
        total: response.total
      } as DataResult)
    ));
  }

  public getUsersByGroup(site: string, groupId: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}${site}/groups/${groupId}/users`).pipe(map(
      (response: any) => response.filter(u => u.status != 'Blocked').map(user => {
        user.url = `../users/edit/${user.id}`;
        return user;
      })
    ));
  }

  public getAllProfiles(): Observable<Profile[]> {
    return this.http.get(`${environment.apiGatewayBaseUrl}/${environment.apiUrl}logik-schema/admin/v1/fields/UserProfile/options`).pipe(map(
      (response: any) => response.content
    ));
  }
}
