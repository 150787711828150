import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataResult } from '@progress/kendo-data-query';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Email } from '../models/email.model';

@Injectable({
  providedIn: 'root'
})
export class WarehouseService {

  constructor(private http: HttpClient) { }

  public getCalls(site: string, whse: string): Observable<DataResult> {
    return this.http.get(`${environment.apiUrl}${site}/whses/${whse}/calls`).pipe(map(
      (res: any) => {
        if (res.response && res.response.data) {
          return ({
            data: res.response.data,
            total: res.response.data.length
          } as DataResult);
        } else {
          return ({
            data: [],
            total: 0
          } as DataResult);
        }
      }
    ));
  }

  public createCall(site: string, whse: string, call: any): Observable<Object> {
    return this.http.post(`${environment.apiUrl}${site}/whses/${whse}/calls`, call);
  }

  public getTexts(site: string, whse: string): Observable<DataResult> {
    return this.http.get(`${environment.apiUrl}${site}/whses/${whse}/texts`).pipe(map(
      (res: any) => {
        return {
          data: [...res?.response?.data],
          total: res?.response?.data?.length ?? 0
        } as DataResult;
      }
    ));
  }

  public createText(site: string, whse: string, text: any): Observable<Object> {
    return this.http.post(`${environment.apiUrl}${site}/whses/${whse}/texts`, text);
  }

  public getEmails(site: string, whse: string): Observable<DataResult> {
    return this.http.get<Email[]>(`${environment.apiUrl}${site}/whses/${whse}/emails`).pipe(map(emails => {
      return {
        data: [...emails],
        total: emails?.length ?? 0
      } as DataResult;
    }));
  }

  public createEmail(site: string, whse: string, email: any): Observable<Object> {
    return this.http.post(`${environment.apiUrl}${site}/whses/${whse}/emails`, email);
  }
}
