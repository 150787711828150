import { Component, OnInit, EventEmitter, Output, Input, ViewChild, ElementRef } from '@angular/core';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-common-header-control',
  templateUrl: './common-header-control.component.html',
  styleUrls: ['./common-header-control.component.scss']
})
export class CommonHeaderControlComponent implements OnInit {
  @Output() emitClickSave = new EventEmitter();
  @Output() emitClickCancel = new EventEmitter();

  @Input() showSaveButton: Observable<boolean> = of(false);
  @Input() disabledSaveButton: Observable<boolean> = of(false);
  @Input() showCancelbutton: Observable<boolean> = of(true);
  
  @Input() saveLabel: string = 'Save';
  @Input() cancelLabel: string = 'Cancel';

  constructor() { }

  ngOnInit() {
  }

  clickSave(e) {
    this.emitClickSave.emit(e);
  }

  clickCancel(e) {
    this.emitClickCancel.emit(e);
  }

}
