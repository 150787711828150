import { Injectable } from '@angular/core';
import { ReturnOrderService } from './return-order.service';
import { ReturnType } from '../models/return-type';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SiteService } from './site.service';

@Injectable()
export class CustomOrderService extends ReturnOrderService {

  private apiUrlExt = environment.apiUrl;
  private topItemsExt = 10000;

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  public getCustomTypes(): Observable<ReturnType[]> {
    return this.httpClient
      .get(`${this.apiUrlExt}orders/customTypes?$top=${this.topItemsExt}`)
      .pipe(map((response: any) => response));
  }
}
