import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, first, map, shareReplay } from 'rxjs/operators';
import { AccountService } from './account.service';
import { SiteService } from './site.service';

@Injectable({
  providedIn: 'root'
})
export class OperationService {

  private operationsSubject: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  readonly operations = this.operationsSubject.pipe(
    distinctUntilChanged(),
    shareReplay(1)
  );

  constructor(private siteService: SiteService, private account: AccountService) {
    this.siteService.currentSite.subscribe(site => {
      if (site) {
        this.account.operations(site).subscribe(operations => this.setOperations(operations));
      } else {
        this.setOperations([]);
      }
    })
  }

  hasAnyPermissionsAsync(...operations: string[]) : Observable<boolean> {
    return this.operations.pipe(map(hasOperations =>operations.filter(o => hasOperations.indexOf(o) >= 0).length > 0));
  }

  hasAnyPermissions(...operations: string[]) : boolean {
    return operations.filter(o => this.operationsSubject.value.indexOf(o) >= 0).length > 0;
  }

  private setOperations(operations: string[]) {
    console.log(`${OperationService.name}#${OperationService.prototype.setOperations.name}: ${JSON.stringify(operations)}`);
    this.operationsSubject.next(operations);
  }
}
