import { Pipe, PipeTransform } from '@angular/core';
import { OrderItem } from '../../core/models/orderItem';
import { ORDER_SOURCE_TYPES } from '../../app.constant';

@Pipe({
  name: 'deliveryWarehouseName'
})
export class DeliveryWarehouseNamePipe implements PipeTransform {

  transform(item: OrderItem, args?: any): any {
    if (item) {
      if (item.order) {
        const source = item.order.sourceType;
        if (source === ORDER_SOURCE_TYPES.JOB) {
          if (item.job && item.job.warehouse) {
            return `${item.job.warehouse.whse} - ${item.job.warehouse.name}`;
          }
        } else {
          if (item.warehouse) {
            return `${item.warehouse.whse} - ${item.warehouse.name}`;
          }
        }
      } else {
        if (item.warehouse) {
          return `${item.warehouse.whse} - ${item.warehouse.name}`;
        }
      }
    }

  }
}
