import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataSourceRequestState, DataResult, toODataString } from '@progress/kendo-data-query';
import { PermitPlanStatus } from '../models/permitPlanStatus';
import { PERMIT_TYPE } from 'src/app/app.constant';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PermitPlanStatusService {

  constructor(private http: HttpClient) { }

  public getType() {
    return [
      {
        id: PERMIT_TYPE.PROCESSING,
        desc: PERMIT_TYPE.PROCESSING,
      },
      {
        id: PERMIT_TYPE.FINAL,
        desc: PERMIT_TYPE.FINAL,
      }
    ];
  }

  public getStatus() {
    return [
      {
        value: 'Active',
        desc: 'Active',
      },
      {
        value: 'Inactive',
        desc: 'Inactive',
      }
    ];
  }

  private localToRemote(pmtStatus: PermitPlanStatus): PermitPlanStatus {
    return {
      ...pmtStatus
    };
  }

  private remoteToLocal(pmtStatus: any): PermitPlanStatus {
    return {
      ...pmtStatus
    } as PermitPlanStatus;
  }

  public get(site: string, id: string): Observable<PermitPlanStatus> {
    return this.http.get(`${environment.apiUrl}${site}/permitPlanStatuses/${id}`).pipe(map(
      (response: any) => this.remoteToLocal(response)
    ));
  }

  public create(site: string, pmtStatus: PermitPlanStatus): Observable<PermitPlanStatus> {
    const remoteItem = this.localToRemote(pmtStatus);
    return this.http.post<PermitPlanStatus>(`${environment.apiUrl}${site}/permitPlanStatuses`, remoteItem);
  }

  public update(site: string, pmtStatus: PermitPlanStatus): Observable<PermitPlanStatus> {
    const remoteItem = this.localToRemote(pmtStatus);
    return this.http.put<PermitPlanStatus>(`${environment.apiUrl}${site}/permitPlanStatuses/${remoteItem.id}`, remoteItem);
  }

  public delete(site: string, pmtStatus: PermitPlanStatus): Observable<any> {
    return this.http.delete<PermitPlanStatus>(`${environment.apiUrl}${site}/permitPlanStatuses/${pmtStatus.id}`);
  }

  public deleteMany(site: string, pmtStatuses: PermitPlanStatus[]): Observable<any> {
    return this.http.request('delete', `${environment.apiUrl}${site}/permitPlanStatuses`, {
      headers: {
        ['Content-Type']: 'application/json'
      },
      body: pmtStatuses
    });
  }

  public fetch(site: string, state: DataSourceRequestState): Observable<DataResult> {
    const queryStr = `${toODataString(state)}`; // Serialize the state
    return this.http.get(`${environment.apiUrl}${site}/permitPlanStatuses?${queryStr}`).pipe(map(
      (response: any) => ({
        data: response.data.map((item: any) => this.remoteToLocal(item)),
        total: response.total
      } as DataResult)
    ));
  }

  public fetchBySite(site: string, state: DataSourceRequestState): Observable<DataResult> {
    var siteState: DataSourceRequestState = {
      ...state,
      filter: {
        logic: 'and',
        filters: [
          ...state.filter.filters,
          {
            field: 'site',
            operator: 'eq',
            value: site
          }
        ]
      }
    }
    return this.fetch(site, siteState);
  }
}
