import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  ChangeDetectionStrategy,
  // Input,
  // ChangeDetectorRef
} from '@angular/core';
import { FilterTemplateDirective } from './filter-template.directive';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { FilterBaseComponent } from '../filter-base.component';
import { OOMSFilterDescriptor, FilterDescriptorEx, CompositeFilterDescriptorEx } from '../ooms-filter-descriptor';
import { FilterDialogParams } from './filter-dialog-params';

@Component({
  selector: 'filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterDialogComponent<T extends FilterBaseComponent<OOMSFilterDescriptor>> extends DialogContentBase implements OnInit, OnDestroy {
  private filterInstance: T;
  private _params: FilterDialogParams;

  @ViewChild(FilterTemplateDirective, { static: true })
  filterTemplateHost: FilterTemplateDirective;

  advancedMode = false;

  set params(params: FilterDialogParams) {
    this._params = params;
    this.advancedMode = this._params.advancedMode;
  }

  get params(): FilterDialogParams {
    return this._params;
  }

  constructor(
    // private ref: ChangeDetectorRef,
    dialog: DialogRef
  ) {
    super(dialog);
  }

  get filterValid(): boolean {
    if (!!this.filterInstance) {
      return !!this.filterInstance.valid ? this.filterInstance.valid : false;
    } else {
      return false;
    }
  }

  get value(): FilterDescriptorEx | CompositeFilterDescriptorEx {
    if (!!this.filterInstance) {
      return !!this.filterInstance.value ? this.filterInstance.value : null;
    } else {
      return null;
    }
  }

  ngOnDestroy(): void {}

  ngOnInit(): void {
    this.refreshTemplate();
  }

  refreshTemplate(): void {
    if (this.advancedMode) {
      this.loadTemplate(this.params.descriptors.advancedDescriptor);
    } else {
      this.loadTemplate(this.params.descriptors);
    }
  }

  loadTemplate(filterDescriptor: OOMSFilterDescriptor): void {
    const viewContainerRef = this.filterTemplateHost.viewContainerRef;

    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent<T>(filterDescriptor.filterComponent);
    componentRef.instance.descriptor = filterDescriptor;
    if (this.params.filterValue && this.advancedMode === this.params.advancedMode) {
      componentRef.instance.defaultFilter = { ...this.params.filterValue };
    }
    this.filterInstance = componentRef.instance;
  }

  toggleMode() {
    this.advancedMode = !this.advancedMode;

    this.refreshTemplate();
  }

  public onCancelAction(): void {
    this.dialog.close({});
  }

  public onConfirmAction(): void {
    this.dialog.close({ themeColor: 'primary' });
  }
}
