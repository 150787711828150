import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';

import { setData, localeData, CldrIntlService } from '@progress/kendo-angular-intl';
import { DialogsModule } from '@progress/kendo-angular-dialog';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';

import { ErrorInterceptor } from './shared/interceptors/error.interceptor';

import { environment } from '../environments/environment';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    CoreModule.forRoot(),
    SharedModule,
    BrowserAnimationsModule,
    DialogsModule,
    CommonModule,

    AuthModule.forRoot({
      domain: environment.auth0.domain,
      clientId: environment.auth0.clientId,

      httpInterceptor: {
        allowedList: [{
          uri: `${environment.apiUrl}*`,
          tokenOptions: {
            audience: environment.auth0.audiences.ooms
          }
        },
        {
          uri: `${environment.apiGatewayBaseUrl}*`,
          tokenOptions: {
            audience: environment.auth0.audiences.ooms
          }
        }]
      }
    })
  ],
  providers: [
    CldrIntlService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private cldrIntlService: CldrIntlService) {
    try {
      const localeDataCurrent = localeData(this.cldrIntlService.localeId);
      if (localeDataCurrent) {
        localeDataCurrent['firstDay'] = environment.firstDay;
        setData(localeDataCurrent);
      }
    } catch (e) {
      console.error('Can not change firstDay of current locale!');
    }
  }
}
