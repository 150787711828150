import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, Data } from '@angular/router';
import { filter, map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  module: string | null = null;
  action: string | null = null;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    ).subscribe(() => {
      this.buildBreadCrumb();
    });
  }

  buildBreadCrumb() {
    this.module = null;
    this.action = null;

    let root = this.router.routerState.root.snapshot;
    let module = null;
    while (!this.module) {
      this.module = root ? root.data.breadcrumb : null;
      if (this.module) {
        module = root;
      } else if (root) {
        root = root.firstChild;
      } else {
        break;
      }
    }

    const action = module ? module.firstChild : null;
    this.action = action ? action.data.breadcrumb : null;
  }
}
