import {EventEmitter, Injectable, ElementRef} from '@angular/core';
import {Event, NavigationEnd, Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class NavService {
  public appDrawer: any;
  public closedToggle: ElementRef;
  public openedToggle: ElementRef;
  public currentUrl = new BehaviorSubject<string>(undefined);

  constructor(private router: Router) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl.next(event.urlAfterRedirects);
      }
    });
  }

  public closeNav() {
    this.appDrawer.close();
    this.closedToggle.nativeElement.style.display = 'none';
    this.openedToggle.nativeElement.style.display = 'inline-block';
  }

  public openNav() {
    this.appDrawer.open();
    this.closedToggle.nativeElement.style.display = 'inline-block';
    this.openedToggle.nativeElement.style.display = 'none';
  }
}
