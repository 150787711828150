import { Pipe, PipeTransform } from '@angular/core';
import { OrderItem } from '../../core/models/orderItem';
import { ORDER_TYPES } from '../../app.constant';

@Pipe({
  name: 'shipaddress'
})
export class ShipAddressPipe implements PipeTransform {

  transform(item: OrderItem, args?: any): any {
    if (!item || !item.order) {
      return '';
    }

    let data;
    if (item.order.type === ORDER_TYPES.STOCK) {
      data = item.order.warehouse || item.warehouse;
    } else {
      data = item.customer || item.order.customer;
    }

    if (!data) {
      return '';
    }

    let listStr = [];
    listStr = this.pushStr(listStr, data.address1);
    listStr = this.pushStr(listStr, data.address2);
    listStr = this.pushStr(listStr, data.address3);
    listStr = this.pushStr(listStr, data.address4);
    listStr = this.pushStr(listStr, data.city);
    listStr = this.pushStr(listStr, data.state);
    listStr = this.pushStr(listStr, data.zip);

    return listStr.join(' - ');
  }

  pushStr(list, el) {
    if (el && el.length > 0) {
      list.push(el);
    }
    return list;
  }

}
