import { Component, Input, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { DatePipe } from '@angular/common';

import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';

import { DateUtil } from 'src/app/core/utils/date.util';
import { FilterDescriptorEx, CompositeFilterDescriptorEx, OOMSFilterDescriptor } from '../ooms-filter-descriptor';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [DatePipe]
})
export class FiltersComponent {
  @Input() public filters: Array<FilterDescriptorEx | CompositeFilterDescriptorEx>;
  // @Input() public filterTitles: Array<{
  //   field: string | string[];
  //   text: string;
  // }>;
  @Input() public filterTitles: Array<OOMSFilterDescriptor>;

  @Output() clearAllFilter = new EventEmitter();
  @Output() clearFilter = new EventEmitter<FilterDescriptor | CompositeFilterDescriptor>();
  @Output() filterEdit = new EventEmitter<FilterDescriptorEx | CompositeFilterDescriptorEx>();

  constructor(private datepipe: DatePipe) {}

  isComposite(filter: FilterDescriptor | CompositeFilterDescriptor) {
    return 'filters' in filter;
  }

  getTitle(filter: FilterDescriptorEx | CompositeFilterDescriptorEx): string {
    return filter.name;
  }

  getOperation(filter: FilterDescriptorEx | CompositeFilterDescriptorEx): string {
    let isDate = false;
    if (this.isComposite(filter)) {
      const cfd = filter as CompositeFilterDescriptorEx;
      // console.log(cfd);
      if (cfd.values.length > 0) {
        isDate = cfd.values[0] instanceof Date;
      }
    } else {
      const fd = filter as FilterDescriptorEx;
      isDate = fd.value instanceof Date;
    }

    switch (filter.operator) {
      case 'neq':
        return ' does not equal to ';
      case 'gt':
        return isDate ? ' after ' : ' greater than ';
      case 'gte':
        return ' greater than or equal to ';
      case 'lt':
        return isDate ? ' before ' : ' less than ';
      case 'lte':
        return ' less than or equal to ';
      case 'startswith':
        return ' begins with ';
      case 'endswith':
        return ' ends with ';
      case 'contains':
        return ' contains ';
      case 'doesnotcontain':
        return ' does not contain ';
      default:
        return ':';
    }
  }

  getValue(filter: FilterDescriptorEx | CompositeFilterDescriptorEx): string {
    if ((filter as FilterDescriptorEx).value === null) {
      if ((filter as FilterDescriptorEx).operator == 'ne') {
        return 'Yes';
      }
      return 'No';
    }
    if (this.isComposite(filter)) {
      const cfd = filter as CompositeFilterDescriptorEx;
      if (cfd.operator === 'bt' && cfd.values.length === 2) {
        // between
        let val1 = cfd.values[0];
        let val2 = cfd.values[1];
        if (val1 instanceof Date) {
          val1 = this.datepipe.transform(DateUtil.toLocalDate(val1), 'M/d/y');
        }
        if (val2 instanceof Date) {
          val2 = this.datepipe.transform(DateUtil.toLocalDate(val2), 'M/d/y');
        }
        return `${val1} ~ ${val2}`;
      }

      // collection
      const values = cfd.values.map((v) => {
        if (v instanceof Date) {
          return this.datepipe.transform(DateUtil.toLocalDate(v), 'M/d/y');
        } else {
          const fd = filter as FilterDescriptorEx;
          if (fd.mapValueTitle !== undefined) {
            return fd.mapValueTitle.find(item => v === item.value).title;
          } else {
            return v as string;
          }
        }
      });
      return values.join(', ');
    } else {
      const fd = filter as FilterDescriptorEx;
      const v = fd.value;
      return v instanceof Date ? this.datepipe.transform(DateUtil.toLocalDate(v), 'M/d/y') : (v as string);
    }
  }

  showCount(filter: FilterDescriptorEx | CompositeFilterDescriptorEx): boolean {
    if (this.isComposite(filter)) {
      const cfd = filter as CompositeFilterDescriptorEx;
      if (cfd.values.length === 2 && cfd.operator === 'bt') {
        return false;
      } else if (!cfd.operator) {
        return false;
      } else if (cfd.values.length > 1) {
        return true;
      }
    }
    return false;
  }

  getCount(filter: CompositeFilterDescriptorEx): number {
    return filter.values.length;
  }
}
