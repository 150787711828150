import { Pipe, PipeTransform } from '@angular/core';
import { OrderItem } from 'src/app/core/models/orderItem';
import { Job } from 'src/app/core/models/job';
import { AbstractSerialPipe } from './AbstractSerialPipe';
import { ORDER_STATUS } from 'src/app/app.constant';

@Pipe({
  name: 'lot'
})
export class LotPipe extends AbstractSerialPipe implements PipeTransform {
  transform(item: OrderItem | Job, args?: any): string {
    if (!item) {
      return null;
    }

    if (this.isJob(item)) {
      item = <Job>item;
      if (item.orderItem && this.isSourceTypeJob(item.orderItem)
        && ![ORDER_STATUS.FILLED, ORDER_STATUS.COMPLETE, ORDER_STATUS.HISTORY].includes(item.orderItem.status)) {
        return item.lot && item.lot.lot ? item.lot.lot : '-';
      }
    } else if (this.isOrderItem(item)) {
      item = <OrderItem>item;
      return item.serial && item.serial.lot ? item.serial.lot : '-';
    }
  }
}
