import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataSourceRequestState, DataResult, toODataString } from '@progress/kendo-data-query';
import { ServicePeriod } from '../models/service-period';
import { DateUtil } from '../utils/date.util';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ServicePeriodsService {

  constructor(private http: HttpClient) { }

  public getType() {
    return [
      {
        id: 'Equipment',
        desc: 'Equipment',
      },
      {
        id: 'Users',
        desc: 'User',
      }
    ];
  }

  private localToRemote(servicePeriod: ServicePeriod): ServicePeriod {
    return {
      ...servicePeriod,

      startDate: DateUtil.toUTCDate(servicePeriod.startDate),
      endDate: DateUtil.toUTCDate(servicePeriod.endDate)
    };
  }

  private remoteToLocal(servicePeriod: any): ServicePeriod {
    return {
      ...servicePeriod,

      startDate: DateUtil.stringToLocalDate(servicePeriod.startDate),
      endDate: DateUtil.stringToLocalDate(servicePeriod.endDate)
    } as ServicePeriod;
  }

  public getUnit(): Observable<any> {
    return this.http.get(`${environment.apiUrl}outOfServices/reasons`).pipe(map(
      (response: any) => response
    ));
  }

  public get(site: string, id: string): Observable<ServicePeriod> {
    return this.http.get(`${environment.apiUrl}${site}/outOfServices/${id}`).pipe(map(
      (response: any) => this.remoteToLocal(response)
    ));
  }

  public create(site: string, servicePeriod: ServicePeriod): Observable<ServicePeriod> {
    const remoteItem = this.localToRemote(servicePeriod);
    return this.http.post<ServicePeriod>(`${environment.apiUrl}${site}/outOfServices`, remoteItem);
  }

  public update(site: string, servicePeriod: ServicePeriod): Observable<ServicePeriod> {
    const remoteItem = this.localToRemote(servicePeriod);
    return this.http.put<ServicePeriod>(`${environment.apiUrl}${site}/outOfServices/${remoteItem.id}`, remoteItem);
  }

  public getReason(): Observable<any> {
    return this.http.get(`${environment.apiUrl}outOfServices/reasons`).pipe(map(
      (response: any) => response
    ));
  }

  public delete(site: string, servicePeriod: ServicePeriod): Observable<any> {
    return this.http.delete<ServicePeriod>(`${environment.apiUrl}${site}/outOfServices/${servicePeriod.id}`);
  }

  public deleteMany(site: string, servicePeriods: ServicePeriod[]): Observable<any> {
    return this.http.request('delete', `${environment.apiUrl}${site}/outOfServices`, {
      headers: {
        ['Content-Type']: 'application/json'
      },
      body: servicePeriods
    });
  }

  public fetch(site: string, state: DataSourceRequestState): Observable<DataResult> {
    const queryStr = `${toODataString(state)}`; // Serialize the state
    return this.http.get(`${environment.apiUrl}${site}/outOfServices?${queryStr}`).pipe(map(
      (response: any) => ({
        data: response.data.map((job: any) => this.remoteToLocal(job)),
        total: response.total
      } as DataResult)
    ));
  }
}
