import { Pipe, PipeTransform } from '@angular/core';
import { Job } from './../../core/models/job';
import { OrderItem } from './../../core/models/orderItem';
import { Serial } from './../../core/models/serial';
import { AbstractSerialPipe } from './AbstractSerialPipe';

@Pipe({
  name: 'serial'
})
export class SerialPipe extends AbstractSerialPipe implements PipeTransform {
  transform(holderObject: OrderItem | Job, args?: any): string {
    if (!holderObject) {
      return null;
    }

    const serial: Serial = this.getSerialNoWidthLenght(holderObject);
    return serial ? serial.serNum : this.isJob ? '-' : '-';
  }
}
