import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Pipe({
  name: 'cast'
})
export class CastPipe implements PipeTransform {
  transform<T>(value: any, _type?: (new (...args: any[]) => T) | FormControl): T {
    return value as T;
  }
}
