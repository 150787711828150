<form [formGroup]="formGroup" class="k-form container">
  <div class="k-form-field">
    <kendo-dropdownlist formControlName="operator"
                        [data]="operators"
                        [itemDisabled]="itemDisabled"
                        [textField]="'text'"
                        [valueField]="'value'"
                        [valuePrimitive]="true"
                        (valueChange)="changeOperator($event)"
                        [popupSettings]="{ popupClass: 'group-items' }">
    </kendo-dropdownlist>
  </div>
  <div class="k-form-field">
    <kendo-numerictextbox #valueInput appAutofocus formControlName="value1"
                          [placeholder]="formGroup.get('operator').value === 'bt' ? 'From' : 'Value'">
    </kendo-numerictextbox>
  </div>
  <div class="k-form-field" *ngIf="formGroup.get('operator').value === 'bt'">
    <kendo-numerictextbox formControlName="value2"
                          [ngClass]="{'ng-invalid': formGroup.errors?.toValueInvalid}"
                          [placeholder]="'To'">
    </kendo-numerictextbox>
  </div>
</form>
