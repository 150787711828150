import { ContextMenuComponent } from '@progress/kendo-angular-menu';
import { Component, EventEmitter, Input, Output, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { GridComponent } from '@progress/kendo-angular-grid';

@Component({
  selector: 'app-grid-context-menu',
  templateUrl: './grid-context-menu.component.html',
  styleUrls: ['./grid-context-menu.component.scss']
})
export class GridContextMenuComponent implements OnDestroy {

  @ViewChild('contextmenu')
  public gridContextMenu: ContextMenuComponent;

  @Input()
  public menuItems: any[] = [];

  public specificMenuItems = [];

  @Output()
  public select: EventEmitter<any> = new EventEmitter<any>();

  @Input() public set for(grid: GridComponent) {
      this.unsubscribe();
      this.cellClickSubscription = grid.cellClick.subscribe(this.onCellClick);
  }

  public dataItem: any;

  private cellClickSubscription: Subscription;

  constructor() {
      this.onCellClick = this.onCellClick.bind(this);
  }

  public ngOnDestroy(): void {
      this.unsubscribe();
  }

  public menuItemSelected(menuEvent: any): void {
      this.select.emit({ menuEvent, dataItem: this.dataItem });
  }

  private onCellClick({ dataItem, type, originalEvent }): void {
    if (type === 'contextmenu') {
      originalEvent.preventDefault();
      this.specificMenuItems = [];
      for (const menuItem of this.menuItems) {
        if (menuItem.hideConditions && !menuItem.hideConditions(dataItem)) {
          this.specificMenuItems.push(menuItem);
        }
      }
      this.gridContextMenu.show({ left: originalEvent.pageX, top: originalEvent.pageY });
      this.dataItem = dataItem;
    }
  }

  private unsubscribe(): void {
      if (this.cellClickSubscription) {
          this.cellClickSubscription.unsubscribe();
          this.cellClickSubscription = null;
      }
  }
}
